import { useState } from "react"
import { Badge, Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import { nombreDNIPorPais } from "../../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../../general/campos_obligatorios";
import { PiWarehouseDuotone } from "react-icons/pi";
import { CiRoute } from "react-icons/ci";
import { AiFillPlusCircle, AiOutlineContacts } from "react-icons/ai";
import { fechaATexto, validateEmail } from "../../../lib/helpers/helpers";
import CamposObligatoriosSimbolo from "../../general/campo_requerido";
import { BsAsterisk, BsFillTrashFill } from "react-icons/bs";
import SelectorZonasEmpresa from "../../../components/Zonas/selector_zonas";
import { modulo, status, types } from "./data";
import { LuTicket } from "react-icons/lu";
import { FaCheckCircle, FaRegClock } from "react-icons/fa";
import OrdenMensajesListado from "./ticket_mensajes";
import NewMessageTicket from "./crear_mensaje";
import CargaImagenes from "../../general/carga_imagenes";
import { IoIosAttach } from "react-icons/io";
import ModalOrdenesEditar from "../../recolecciones/modal_ordenes_editar";
import { estilo_last_mile } from "../../../lib/global/styles";
import ModalListadoOrdenes from "../modal_listado";

const DetailFull = (props) => {
    const {
        id_orden
    } = props
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [ condicionMensajes, setCondicionMensajes ] = useState({ id_ticket: proveedor._id })
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [ ordenSeleccionada, setOrdenSeleccionada ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ archivos, setArchivos ] = useState([])
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);

    
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {

        const requeridos = [
            { value:'titulo', label: 'Título'},
            { value: "type", label: "Tipo" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        if(id_orden) {
            nuevoProveedor.id_orden = id_orden
        } else {
            if(ordenSeleccionada) nuevoProveedor.id_orden = ordenSeleccionada?._id
        }
        nuevoProveedor.atachment = archivos
        return fetch(`${data.urlapi}/ordenes/${modulo}`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
                    setArchivos([])
                    handleCloseModal()
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo efectuar la operación')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/${modulo}`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }


    // NUEVO REGISTRO 

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        nuevoProveedor.emails_adicionales[pos][name] = value
        nuevoProveedor.emails_adicionales[pos].valid = validateEmail(value)
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }

    const handleChangeMovil = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        nuevoProveedor.moviles_adicionales[pos][name] = value
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }

    const anadirEmailAdicional = () => {
        if(!nuevoProveedor.emails_adicionales) nuevoProveedor.emails_adicionales = []
        nuevoProveedor.emails_adicionales.unshift({
            email: ''
        })
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }
    const anadirMovilAdicional = () => {
        if(!nuevoProveedor.moviles_adicionales) nuevoProveedor.moviles_adicionales = []
        nuevoProveedor.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!nuevoProveedor.emails_adicionales) return false
        return <div>
            {
                nuevoProveedor.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-1">
                                <label className='form-control-label d-block mb-1' style={{fontWeight:700}}>Email</label>
                                <div className='d-flex justify-content-between'>
                                <input placeholder="Escribe aquí tu email" className={`form-control mb-1 shadow-sm mr-1 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                <b className="hover mx-1" onClick={() => removerEmail(i)}><i className="fa-solid fa-circle-xmark text-danger"></i></b>
                                </div>
                    </div>
                })
            }
        </div>
    }

    const removerEmail = (i) => {
        nuevoProveedor.emails_adicionales.splice(i,1)
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor})) 
    }

    const removerMovil = (i) => {
        nuevoProveedor.moviles_adicionales.splice(i,1)
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor})) 
    }
    const mostrarMovilesAdicionales = () => {
        if(!nuevoProveedor.moviles_adicionales) return false
        return <div>
            {
                nuevoProveedor.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-1">
                    <label className='form-control-label d-block mb-1' style={{fontWeight:700}}>Móvil</label>
                        <div className='d-flex justify-content-between'>
                            <select className='form-control shadow-sm col-md-4' defaultValue="" pos={i} name="pais_codigo" value={field.pais_codigo} onChange={handleChangeMovil}>
                            <option value="">Código Pais</option>
                            <option data-countryCode="CL" value="56">Chile (+56)</option>
                            <option data-countryCode="BR" value="55">Brazil (+55)</option>
                            <optgroup label="Otros países">
                                <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                                <option data-countryCode="AD" value="376">Andorra (+376)</option>
                                <option data-countryCode="AO" value="244">Angola (+244)</option>
                                <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                                <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                                <option data-countryCode="AR" value="54">Argentina (+54)</option>
                                <option data-countryCode="AM" value="374">Armenia (+374)</option>
                                <option data-countryCode="AW" value="297">Aruba (+297)</option>
                                <option data-countryCode="AU" value="61">Australia (+61)</option>
                                <option data-countryCode="AT" value="43">Austria (+43)</option>
                                <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                                <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                                <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                                <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                                <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                                <option data-countryCode="BY" value="375">Belarus (+375)</option>
                                <option data-countryCode="BE" value="32">Belgium (+32)</option>
                                <option data-countryCode="BZ" value="501">Belize (+501)</option>
                                <option data-countryCode="BJ" value="229">Benin (+229)</option>
                                <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                                <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                                <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                                <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                                <option data-countryCode="BW" value="267">Botswana (+267)</option>
                                <option data-countryCode="BN" value="673">Brunei (+673)</option>
                                <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                                <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                                <option data-countryCode="BI" value="257">Burundi (+257)</option>
                                <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                                <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                                <option data-countryCode="CA" value="1">Canada (+1)</option>
                                <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                                <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                                <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                                <option data-countryCode="CN" value="86">China (+86)</option>
                                <option data-countryCode="CO" value="57">Colombia (+57)</option>
                                <option data-countryCode="KM" value="269">Comoros (+269)</option>
                                <option data-countryCode="CG" value="242">Congo (+242)</option>
                                <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                                <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                                <option data-countryCode="HR" value="385">Croatia (+385)</option>
                                <option data-countryCode="CU" value="53">Cuba (+53)</option>
                                <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                                <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                                <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                                <option data-countryCode="DK" value="45">Denmark (+45)</option>
                                <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                                <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                                <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                                <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                                <option data-countryCode="EG" value="20">Egypt (+20)</option>
                                <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                                <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                                <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                                <option data-countryCode="EE" value="372">Estonia (+372)</option>
                                <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                                <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                                <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                                <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                                <option data-countryCode="FI" value="358">Finland (+358)</option>
                                <option data-countryCode="FR" value="33">France (+33)</option>
                                <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                                <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                                <option data-countryCode="GA" value="241">Gabon (+241)</option>
                                <option data-countryCode="GM" value="220">Gambia (+220)</option>
                                <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                                <option data-countryCode="DE" value="49">Germany (+49)</option>
                                <option data-countryCode="GH" value="233">Ghana (+233)</option>
                                <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                                <option data-countryCode="GR" value="30">Greece (+30)</option>
                                <option data-countryCode="GL" value="299">Greenland (+299)</option>
                                <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                                <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                                <option data-countryCode="GU" value="671">Guam (+671)</option>
                                <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                                <option data-countryCode="GN" value="224">Guinea (+224)</option>
                                <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                                <option data-countryCode="GY" value="592">Guyana (+592)</option>
                                <option data-countryCode="HT" value="509">Haiti (+509)</option>
                                <option data-countryCode="HN" value="504">Honduras (+504)</option>
                                <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                                <option data-countryCode="HU" value="36">Hungary (+36)</option>
                                <option data-countryCode="IS" value="354">Iceland (+354)</option>
                                <option data-countryCode="IN" value="91">India (+91)</option>
                                <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                                <option data-countryCode="IR" value="98">Iran (+98)</option>
                                <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                                <option data-countryCode="IE" value="353">Ireland (+353)</option>
                                <option data-countryCode="IL" value="972">Israel (+972)</option>
                                <option data-countryCode="IT" value="39">Italy (+39)</option>
                                <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                                <option data-countryCode="JP" value="81">Japan (+81)</option>
                                <option data-countryCode="JO" value="962">Jordan (+962)</option>
                                <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                                <option data-countryCode="KE" value="254">Kenya (+254)</option>
                                <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                                <option data-countryCode="KP" value="850">Korea North (+850)</option>
                                <option data-countryCode="KR" value="82">Korea South (+82)</option>
                                <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                                <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                                <option data-countryCode="LA" value="856">Laos (+856)</option>
                                <option data-countryCode="LV" value="371">Latvia (+371)</option>
                                <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                                <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                                <option data-countryCode="LR" value="231">Liberia (+231)</option>
                                <option data-countryCode="LY" value="218">Libya (+218)</option>
                                <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                                <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                                <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                                <option data-countryCode="MO" value="853">Macao (+853)</option>
                                <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                                <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                                <option data-countryCode="MW" value="265">Malawi (+265)</option>
                                <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                                <option data-countryCode="MV" value="960">Maldives (+960)</option>
                                <option data-countryCode="ML" value="223">Mali (+223)</option>
                                <option data-countryCode="MT" value="356">Malta (+356)</option>
                                <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                                <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                                <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                                <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                                <option data-countryCode="MX" value="52">Mexico (+52)</option>
                                <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                                <option data-countryCode="MD" value="373">Moldova (+373)</option>
                                <option data-countryCode="MC" value="377">Monaco (+377)</option>
                                <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                                <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                                <option data-countryCode="MA" value="212">Morocco (+212)</option>
                                <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                                <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                                <option data-countryCode="NA" value="264">Namibia (+264)</option>
                                <option data-countryCode="NR" value="674">Nauru (+674)</option>
                                <option data-countryCode="NP" value="977">Nepal (+977)</option>
                                <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                                <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                                <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                                <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                                <option data-countryCode="NE" value="227">Niger (+227)</option>
                                <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                                <option data-countryCode="NU" value="683">Niue (+683)</option>
                                <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                                <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                                <option data-countryCode="NO" value="47">Norway (+47)</option>
                                <option data-countryCode="OM" value="968">Oman (+968)</option>
                                <option data-countryCode="PW" value="680">Palau (+680)</option>
                                <option data-countryCode="PA" value="507">Panama (+507)</option>
                                <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                                <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                                <option data-countryCode="PE" value="51">Peru (+51)</option>
                                <option data-countryCode="PH" value="63">Philippines (+63)</option>
                                <option data-countryCode="PL" value="48">Poland (+48)</option>
                                <option data-countryCode="PT" value="351">Portugal (+351)</option>
                                <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                                <option data-countryCode="QA" value="974">Qatar (+974)</option>
                                <option data-countryCode="RE" value="262">Reunion (+262)</option>
                                <option data-countryCode="RO" value="40">Romania (+40)</option>
                                <option data-countryCode="RU" value="7">Russia (+7)</option>
                                <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                                <option data-countryCode="SM" value="378">San Marino (+378)</option>
                                <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                                <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                                <option data-countryCode="SN" value="221">Senegal (+221)</option>
                                <option data-countryCode="CS" value="381">Serbia (+381)</option>
                                <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                                <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                                <option data-countryCode="SG" value="65">Singapore (+65)</option>
                                <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                                <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                                <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                                <option data-countryCode="SO" value="252">Somalia (+252)</option>
                                <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                                <option data-countryCode="ES" value="34">Spain (+34)</option>
                                <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                                <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                                <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                                <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                                <option data-countryCode="SD" value="249">Sudan (+249)</option>
                                <option data-countryCode="SR" value="597">Suriname (+597)</option>
                                <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                                <option data-countryCode="SE" value="46">Sweden (+46)</option>
                                <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                                <option data-countryCode="SI" value="963">Syria (+963)</option>
                                <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                                <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                                <option data-countryCode="TH" value="66">Thailand (+66)</option>
                                <option data-countryCode="TG" value="228">Togo (+228)</option>
                                <option data-countryCode="TO" value="676">Tonga (+676)</option>
                                <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                                <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                                <option data-countryCode="TR" value="90">Turkey (+90)</option>
                                <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                                <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                                <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                                <option data-countryCode="UG" value="256">Uganda (+256)</option>
                                <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                                <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                                <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                                <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                                <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                                <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                                <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                                <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                                <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                                <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                                <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                                <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                                <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                                <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                                <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                            </optgroup>
                            </select>
                            <input placeholder="Escribe aquí el número telefónico" className='form-control shadow-sm mx-1' pos={i}  name="movil" value={field.movil} onChange={handleChangeMovil} />
                            <b className="hover mx-1" onClick={() => removerMovil(i)}><i className="fa-solid fa-circle-xmark text-danger"></i></b>
                            </div>
                    </div>
                })
            }
        </div>
    }

    const agregarArchivo = (url,name) => {
        console.log(url,name)
        return setArchivos(prev => {
            let actual = [...prev]
            actual.unshift({
                url,
                name
            })
            return [...actual]
        })
    }

    const removerArchivo = (i) => {
        setArchivos(prev => {
            let actual  = [...prev]
            actual.splice(i,1)
            return [...actual]
        })
    }

    const asignarOrden = (data) => {
        setOrdenSeleccionada(data)
    }

    const formularioNuevo = () => {
        return <div>
            {/* <h5 className='mb-0' style={{fontWeight:900}}><LuTicket /> Crear ticket</h5>
                <p className="p-0 m-0"><i className="fa-solid fa-circle-info text-warning"></i> Puedes generar un ticket de atención sobre ordenes.</p> */}
                <Button className="shadow-sm mr-2" size="sm" variant="primary" onClick={handleOpenModal}><b>CREAR CONDUCTOR <i className="fa-solid fa-angles-right"></i></b></Button>
        <Row>
            
            <Modal show={showModal} onHide={handleCloseModal} size="lg"  >
                <Modal.Header closeButton className="pb-0 ">
                    <Modal.Title className="text-primary" style={{fontWeight:900}}>CREAR CONDUCTOR</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 text-primary">
                    <p className="text-secondary mb-0 pb-0" style={{fontWeight:700}}>Los campos marcados con <BsAsterisk/> son obligatorios</p>
                <hr />
                <Row>
                <Col md={3} className="">
                    <div>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Nombres <CamposObligatoriosSimbolo /></label>
                    <input className="form-control shadow-sm" name="nombres" value={nuevoProveedor.nombres} onChange={handleChangeNuevo} />
                    </div>
                </Col>
                <Col md={3} className="">
                    <div>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Apellidos <CamposObligatoriosSimbolo /></label>
                    <input className="form-control shadow-sm" name="apellidos" value={nuevoProveedor.apellidos} onChange={handleChangeNuevo} />
                    </div>
                </Col>
                <Col md={12}>
                <CargaImagenes onUploaded={(url,name) => agregarArchivo(url,name)} />
                {/* <div className="mt-3">
                {
                    archivos.map((archivo,i) => {
                        return <div className='d-flex' key={`ar-${archivo.name}-${i}`}>
                            { archivo.url ? <a href={archivo.url} target="_blank" rel="noreferrer"><h5 style={{fontWeight: "bold", color: "blue" }}><IoIosAttach /> {archivo.name}</h5></a> : <h5><IoIosAttach /> {archivo.name}</h5> }
                            <Button className='ml-2 pt-0 px-0 bg-transparent border-0' style={{fontSize:12}} size="sm" onClick={() => removerArchivo(i)} ><i className="fa-solid fa-circle-xmark text-danger"></i></Button>
                        </div>
                    })
                }
                </div> */}
                </Col>
                {/* <Col md={4} className="">
                    <SelectorZonasEmpresa titulo="Ruta a asignar" onChange={handleChangeZona} />
                </Col> */}
                {/* <Col xs={12}>
                <div className="d-flex justify-content-between mb-2">
                <h5 style={{fontWeight:900}}><i className="fa-solid fa-envelope"></i> Emails adicionales</h5>
                <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirEmailAdicional()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                </div>
                    {mostrarEmailsAdicionales()}

                    <div className="d-flex justify-content-between my-2">
                    <h5 style={{fontWeight:900}}><i className="fa-solid fa-phone"></i> Teléfonos adicionales</h5>
                    <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirMovilAdicional()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                    </div>
                    {mostrarMovilesAdicionales()}

                </Col> */}
                <Col md={12}>
                    <div className="d-flex justify-content-end">
                    <Button size="sm" variant="primary" disabled={loading} className="shadow-sm w-25" onClick={()=>crearNuevo()} ><b>{ loading ? "CREANDO..." : "CREAR TICKET" }</b></Button>
                    </div>
                </Col>
                </Row>
                </Modal.Body>
        </Modal>
        </Row>
        </div>
    }


    const handleChangeEmailActual = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        proveedor.emails_adicionales[pos][name] = value
        proveedor.emails_adicionales[pos].valid = validateEmail(value)
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const handleChangeMovilActual = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        proveedor.moviles_adicionales[pos][name] = value
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const anadirEmailAdicionalActual = () => {
        if(!proveedor.emails_adicionales) proveedor.emails_adicionales = []
        proveedor.emails_adicionales.unshift({
            email: ''
        })
        return setProveedor(prev => ({...{}, ...proveedor}))
    }
    const anadirMovilAdicionalActual = () => {
        if(!proveedor.moviles_adicionales) proveedor.moviles_adicionales = []
        proveedor.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const mostrarEmailsAdicionalesActual = () => {
        if(!proveedor.emails_adicionales) return false
        return <div>
            {
                proveedor.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-1">
                    <label className='form-control-label d-block mb-1' style={{fontWeight:700}}>Email</label>
                    <div className='d-flex justify-content-between'>
                    <input placeholder="Escribe aquí tu email" className={`form-control mb-1 shadow-sm mr-1 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="email" value={field.email} onChange={handleChangeEmailActual} />
                    <b className="hover mx-1" onClick={() => removerEmailActual(i)}><i className="fa-solid fa-circle-xmark text-danger"></i></b>
                    </div>
        </div>
                })
            }
        </div>
    }

    const removerEmailActual = (i) => {
        proveedor.emails_adicionales.splice(i,1)
        return setProveedor(prev => ({...{}, ...proveedor})) 
    }

    const removerMovilActual = (i) => {
        proveedor.moviles_adicionales.splice(i,1)
        return setProveedor(prev => ({...{}, ...proveedor})) 
    }
    const mostrarMovilesAdicionalesActual = () => {
        if(!proveedor.moviles_adicionales) return false
        return <div>
            {
                proveedor.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <label className='form-control-label d-block mb-1' style={{fontWeight:700}}>Móvil</label>
                    <div className='d-flex justify-content-between'>
                    <select className='form-control shadow-sm col-md-4' defaultValue="" pos={i} name="pais_codigo" value={field.pais_codigo} onChange={handleChangeMovilActual}>
                            <option value="">Código Pais</option>
                            <option data-countryCode="CL" value="56">Chile (+56)</option>
                            <option data-countryCode="BR" value="55">Brazil (+55)</option>
                            <optgroup label="Otros países">
                                <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                                <option data-countryCode="AD" value="376">Andorra (+376)</option>
                                <option data-countryCode="AO" value="244">Angola (+244)</option>
                                <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                                <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                                <option data-countryCode="AR" value="54">Argentina (+54)</option>
                                <option data-countryCode="AM" value="374">Armenia (+374)</option>
                                <option data-countryCode="AW" value="297">Aruba (+297)</option>
                                <option data-countryCode="AU" value="61">Australia (+61)</option>
                                <option data-countryCode="AT" value="43">Austria (+43)</option>
                                <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                                <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                                <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                                <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                                <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                                <option data-countryCode="BY" value="375">Belarus (+375)</option>
                                <option data-countryCode="BE" value="32">Belgium (+32)</option>
                                <option data-countryCode="BZ" value="501">Belize (+501)</option>
                                <option data-countryCode="BJ" value="229">Benin (+229)</option>
                                <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                                <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                                <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                                <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                                <option data-countryCode="BW" value="267">Botswana (+267)</option>
                                <option data-countryCode="BN" value="673">Brunei (+673)</option>
                                <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                                <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                                <option data-countryCode="BI" value="257">Burundi (+257)</option>
                                <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                                <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                                <option data-countryCode="CA" value="1">Canada (+1)</option>
                                <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                                <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                                <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                                <option data-countryCode="CN" value="86">China (+86)</option>
                                <option data-countryCode="CO" value="57">Colombia (+57)</option>
                                <option data-countryCode="KM" value="269">Comoros (+269)</option>
                                <option data-countryCode="CG" value="242">Congo (+242)</option>
                                <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                                <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                                <option data-countryCode="HR" value="385">Croatia (+385)</option>
                                <option data-countryCode="CU" value="53">Cuba (+53)</option>
                                <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                                <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                                <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                                <option data-countryCode="DK" value="45">Denmark (+45)</option>
                                <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                                <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                                <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                                <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                                <option data-countryCode="EG" value="20">Egypt (+20)</option>
                                <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                                <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                                <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                                <option data-countryCode="EE" value="372">Estonia (+372)</option>
                                <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                                <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                                <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                                <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                                <option data-countryCode="FI" value="358">Finland (+358)</option>
                                <option data-countryCode="FR" value="33">France (+33)</option>
                                <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                                <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                                <option data-countryCode="GA" value="241">Gabon (+241)</option>
                                <option data-countryCode="GM" value="220">Gambia (+220)</option>
                                <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                                <option data-countryCode="DE" value="49">Germany (+49)</option>
                                <option data-countryCode="GH" value="233">Ghana (+233)</option>
                                <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                                <option data-countryCode="GR" value="30">Greece (+30)</option>
                                <option data-countryCode="GL" value="299">Greenland (+299)</option>
                                <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                                <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                                <option data-countryCode="GU" value="671">Guam (+671)</option>
                                <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                                <option data-countryCode="GN" value="224">Guinea (+224)</option>
                                <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                                <option data-countryCode="GY" value="592">Guyana (+592)</option>
                                <option data-countryCode="HT" value="509">Haiti (+509)</option>
                                <option data-countryCode="HN" value="504">Honduras (+504)</option>
                                <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                                <option data-countryCode="HU" value="36">Hungary (+36)</option>
                                <option data-countryCode="IS" value="354">Iceland (+354)</option>
                                <option data-countryCode="IN" value="91">India (+91)</option>
                                <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                                <option data-countryCode="IR" value="98">Iran (+98)</option>
                                <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                                <option data-countryCode="IE" value="353">Ireland (+353)</option>
                                <option data-countryCode="IL" value="972">Israel (+972)</option>
                                <option data-countryCode="IT" value="39">Italy (+39)</option>
                                <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                                <option data-countryCode="JP" value="81">Japan (+81)</option>
                                <option data-countryCode="JO" value="962">Jordan (+962)</option>
                                <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                                <option data-countryCode="KE" value="254">Kenya (+254)</option>
                                <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                                <option data-countryCode="KP" value="850">Korea North (+850)</option>
                                <option data-countryCode="KR" value="82">Korea South (+82)</option>
                                <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                                <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                                <option data-countryCode="LA" value="856">Laos (+856)</option>
                                <option data-countryCode="LV" value="371">Latvia (+371)</option>
                                <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                                <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                                <option data-countryCode="LR" value="231">Liberia (+231)</option>
                                <option data-countryCode="LY" value="218">Libya (+218)</option>
                                <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                                <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                                <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                                <option data-countryCode="MO" value="853">Macao (+853)</option>
                                <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                                <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                                <option data-countryCode="MW" value="265">Malawi (+265)</option>
                                <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                                <option data-countryCode="MV" value="960">Maldives (+960)</option>
                                <option data-countryCode="ML" value="223">Mali (+223)</option>
                                <option data-countryCode="MT" value="356">Malta (+356)</option>
                                <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                                <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                                <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                                <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                                <option data-countryCode="MX" value="52">Mexico (+52)</option>
                                <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                                <option data-countryCode="MD" value="373">Moldova (+373)</option>
                                <option data-countryCode="MC" value="377">Monaco (+377)</option>
                                <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                                <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                                <option data-countryCode="MA" value="212">Morocco (+212)</option>
                                <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                                <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                                <option data-countryCode="NA" value="264">Namibia (+264)</option>
                                <option data-countryCode="NR" value="674">Nauru (+674)</option>
                                <option data-countryCode="NP" value="977">Nepal (+977)</option>
                                <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                                <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                                <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                                <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                                <option data-countryCode="NE" value="227">Niger (+227)</option>
                                <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                                <option data-countryCode="NU" value="683">Niue (+683)</option>
                                <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                                <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                                <option data-countryCode="NO" value="47">Norway (+47)</option>
                                <option data-countryCode="OM" value="968">Oman (+968)</option>
                                <option data-countryCode="PW" value="680">Palau (+680)</option>
                                <option data-countryCode="PA" value="507">Panama (+507)</option>
                                <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                                <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                                <option data-countryCode="PE" value="51">Peru (+51)</option>
                                <option data-countryCode="PH" value="63">Philippines (+63)</option>
                                <option data-countryCode="PL" value="48">Poland (+48)</option>
                                <option data-countryCode="PT" value="351">Portugal (+351)</option>
                                <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                                <option data-countryCode="QA" value="974">Qatar (+974)</option>
                                <option data-countryCode="RE" value="262">Reunion (+262)</option>
                                <option data-countryCode="RO" value="40">Romania (+40)</option>
                                <option data-countryCode="RU" value="7">Russia (+7)</option>
                                <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                                <option data-countryCode="SM" value="378">San Marino (+378)</option>
                                <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                                <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                                <option data-countryCode="SN" value="221">Senegal (+221)</option>
                                <option data-countryCode="CS" value="381">Serbia (+381)</option>
                                <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                                <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                                <option data-countryCode="SG" value="65">Singapore (+65)</option>
                                <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                                <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                                <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                                <option data-countryCode="SO" value="252">Somalia (+252)</option>
                                <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                                <option data-countryCode="ES" value="34">Spain (+34)</option>
                                <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                                <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                                <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                                <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                                <option data-countryCode="SD" value="249">Sudan (+249)</option>
                                <option data-countryCode="SR" value="597">Suriname (+597)</option>
                                <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                                <option data-countryCode="SE" value="46">Sweden (+46)</option>
                                <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                                <option data-countryCode="SI" value="963">Syria (+963)</option>
                                <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                                <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                                <option data-countryCode="TH" value="66">Thailand (+66)</option>
                                <option data-countryCode="TG" value="228">Togo (+228)</option>
                                <option data-countryCode="TO" value="676">Tonga (+676)</option>
                                <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                                <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                                <option data-countryCode="TR" value="90">Turkey (+90)</option>
                                <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                                <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                                <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                                <option data-countryCode="UG" value="256">Uganda (+256)</option>
                                <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                                <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                                <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                                <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                                <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                                <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                                <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                                <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                                <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                                <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                                <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                                <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                                <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                                <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                                <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                            </optgroup>
                            </select>
                            <input placeholder="Escribe aquí el número telefónico" className='form-control shadow-sm mx-1' pos={i}  name="movil" value={field.movil} onChange={handleChangeMovilActual} />
                            <b className="hover mx-1" onClick={() => removerMovilActual(i)}><i className="fa-solid fa-circle-xmark text-danger"></i></b>
                            </div>
                    </div>
                })
            }
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/ordenes/${modulo}?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const onNewMessage = () => {
        return setCondicionMensajes({ id_ticket: proveedor._id })
    }
    const View = () => {
        if(!proveedor) return formularioNuevo()

        const tipos = {}
        types.map(t => tipos[t.value] = t.label)
        return <div>
            <div className=" mb-2">
            <h4 className="mb-2 text-uppercase" style={{fontWeight:900}}><LuTicket /> {proveedor.titulo}</h4>
            {proveedor.id_orden ? <div className="mb-3"><ModalListadoOrdenes title="TRACKING DE LA ORDEN" condicion={{ _id: proveedor.id_orden }} /></div> : false}
            <Row>
                <Col><p className="mb-0" style={{ fontSize: 12 }}><FaRegClock /> <b style={{ fontWeight: "bold"}}>Creado</b> {fechaATexto(proveedor.createdAt)}</p></Col>
                <Col className="text-right"><p className="mb-0" style={{ fontSize: 12 }}><FaRegClock /> <b style={{ fontWeight: "bold"}}>Última actualización</b> {fechaATexto(proveedor.updatedAt)}</p></Col>
            </Row>
            <hr/>
            <Badge variant="secondary" style={{ textTransform: "uppercase" }} className="mb-2 mr-2">{tipos[proveedor.type]}</Badge>
            <Badge variant={proveedor.open ? "success" : "dark"} style={{ textTransform: "uppercase" }} className="mb-2">{proveedor.open ? "ABIERTO" : "CERRADO"}</Badge>
            <p className="mb-0" style={{ fontWeight: "bold" }}><FaRegClock /> {status[proveedor.status]}</p>
            
            
            </div>
            <Card className="mb-3 p-2">
            <label className="form-control-label d-block mb-0" style={{fontWeight:700}}>Descripción del ticket</label>
            <p className="mb-0">{proveedor.titulo}</p>

            </Card>
            <NewMessageTicket id_ticket={proveedor._id} onNewMessage={(data) => onNewMessage(data)} />
            <OrdenMensajesListado condicion_default={condicionMensajes} /> 
            <Row>
        {/* <Col md={4} className="mb-3">
                    <SelectorZonasEmpresa defaultValue={proveedor.id_zona} titulo="Zona a asignar" onChange={handleChangeZona} />
                </Col>
                <Col xs={12}>
                <div className="d-flex justify-content-between mb-2">
                <h5 style={{fontWeight:900}}><i className="fa-solid fa-envelope"></i> Emails adicionales</h5>
                <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirEmailAdicionalActual()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                </div>
                    {mostrarEmailsAdicionalesActual()}
                    <div className="d-flex justify-content-between my-2">
                    <h5 style={{fontWeight:900}}><i className="fa-solid fa-phone"></i> Teléfonos adicionales</h5>
                    <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirMovilAdicionalActual()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                    </div>
                    {mostrarMovilesAdicionalesActual()}
                </Col> */}
        {/* <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm w-100 my-3' variant="primary" onClick={()=>guardarCambios()} ><b>GUARDAR CAMBIOS</b></Button>
            }
        </Col> */}
        </Row>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull