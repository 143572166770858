import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../../redux/actions/session"
import { Button, ButtonGroup, Col, Row, Spinner, Table } from "react-bootstrap"
import { debounce } from "@mui/material"
import { FaCheckCircle, FaRegClock } from "react-icons/fa"
import { MdUpdateDisabled } from "react-icons/md"
import { GrUpdate } from "react-icons/gr"
import PedidosYaActivar from "./pedidosya"
import UberActivar from "./uber"
import ErrorBoundary from "../../errorsHandler/errorBoundary"
import { url_images } from "../../../lib/global/data"

const DespachoOrden = (props) => {
    const [ error, setError ] = useState(null)
    const { viewType, id_orden } = props
    const [ campos, setCampos ] = useState([])
    const [ guardado, setGuardado ] = useState(true)
    const [ loadingPicking, setLoadingPicking ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    const confirmar = (e) => {
        let logo = ''
        if(e === "uber"){
            logo = `${url_images}/uber.png`
        } else if(e === "pedidosya"){
            logo = `${url_images}/pedidos-ya.png`
        }
        if(!logo) return 
        if(props.onConfirm) props.onConfirm({ proveedor_logo: logo })
    }
    
    const mostrarCampos = () => {
        let titulo_boton = loadingPicking ? "GUARDANDO...": "GUARDAR"
        if(guardado) titulo_boton = "GUARDADO"
        return <div>
            
            <Row>
                <Col md={6} className="text-center"><PedidosYaActivar id_orden={id_orden} onConfirm={() => confirmar("pedidosya")} /></Col>
                <Col md={6} className="text-center"><UberActivar id_orden={id_orden} onConfirm={() => confirmar("uber")} /></Col>
            </Row>
        </div>
    }

    return <div>
        <ErrorBoundary message={error} />
        {mostrarCampos()}
    </div>
}

export default DespachoOrden