import { DateTime } from "luxon"
import { timezone } from "../../backend/data"

export const getNombreDestinatario = (pedido) => {
    let destinatario = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    destinatario = `${pedido.orden.billing.first_name ? pedido.orden.billing.first_name : ''} ${pedido.orden.billing.last_name ? pedido.orden.billing.last_name : ''}`
                }
            }
        }
    }
    return destinatario
}

export const getDireccion = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = `${pedido.orden.billing.address_1} ${pedido.orden.billing.address_2 ? pedido.orden.billing.address_2 : ''}`
                }
            }
        }
    }
    return direccion
}

export const getDireccion1 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_1
                }
            }
        }
    }
    return direccion
}

export const getDireccion2 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_2
                }
            }
        }
    }
    return direccion
}

export const getEmail = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.email}`
                }
            }
        }
    }
    return valor
}

export const getTelefono = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.phone}`
                }
            }
        }
    }
    return valor
}

export const canalEstadosString = (str = String) => {
    switch (str) {
        case 'app_conductor':
            return 'App Conductor'
        case 'sistema-web':
            return 'Sistema Web'
        default:
            return str
    }
}

export const showByTipoServicio = (tipo, tipos) => {
    const i = tipos.findIndex(tip => tip.value === tipo)
    if(i > -1) return tipos[i].label
    return `No identificado`
}

export const obtenerGeoValor = (pedido, key, key_search) => {
    let direccion = ''
    let llave_busqueda =  key_search ? key_search : 'geo_datos'
    if(!key) return "Llave es requerida"
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden[llave_busqueda]){
                if(typeof pedido.orden[llave_busqueda] === 'object'){
                        direccion = pedido.orden[llave_busqueda][key]
                }
            }
        }
    }
    return direccion ? direccion : "Sin información"
}

export const    obtenerGeoDatosGoogle = (informacion) => {
    if(!informacion) return {}
    if(informacion.address_components){
        if(Array.isArray(informacion.address_components) !== false){
            if(informacion.address_components.length > 0){
                let pais = ''
                let codigo_pais = ''
                let level1 = ''
                let level2 = ''
                let level3 = ''
                let lat = 0
                let lng = 0
                let formatted_address = ''

                if(informacion.geometry){
                    if(typeof informacion.geometry === 'object'){
                        if(informacion.geometry.location){
                            if(typeof informacion.geometry.location === 'object'){
                                if(informacion.geometry.location.lat){
                                    lat = informacion.geometry.location.lat
                                    lng = informacion.geometry.location.lng
                                }
                            }
                        }
                    }
                }

                if(informacion.formatted_address){
                    formatted_address = informacion.formatted_address
                }

                const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                if(i_pais > -1){
                    pais = informacion.address_components[i_pais].long_name
                    codigo_pais = informacion.address_components[i_pais].short_name
                }

                const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                if(l1 > -1) level1 = informacion.address_components[l1].long_name
                
                const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                if(l2 > -1) level2 = informacion.address_components[l2].long_name
                
                const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                if(l3 > -1) level3 = informacion.address_components[l3].long_name

                return {
                    pais,
                    codigo_pais,
                    formatted_address,
                    level1,
                    level2,
                    level3,
                    lat,
                    lng
                }
            }
        }
    }
    return false
}

export const calcularPesoVolumetrico = (productos) => {
    let default_weight = 0
    let default_vol = 0

    if(!productos) return {
        default_weight,
        default_vol
    }
    if(!Array.isArray(productos)) return {
        default_weight,
        default_vol
    }
    
    for( const pro of productos ){
        let ancho       = 0
        let alto        = 0
        let largo       = 0
        let peso        = 0
        let cantidad    = 1

        if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
        if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
        if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)
        if(!isNaN( parseFloat(pro.peso) ))      peso    = parseInt(pro.peso)
        if(!isNaN( parseInt(pro.quantity) ))    cantidad= parseInt(pro.quantity)

        
        const total = (ancho * alto * largo) / 1000000
        default_vol = (default_vol + (total * cantidad))
        default_weight = (default_weight + (peso * cantidad))
    }
    
    const peso_vol = Math.max((default_vol / 250), default_weight)

    return {
        default_weight: peso_vol,
        default_vol
    }
}

export const obtenerValorOrden = (objeto, key, default_val) => {
    let default_value = typeof default_val !== "undefined" ? default_val : ""

    if(!objeto.orden) return default_value
    if(typeof objeto.orden !== "object") return default_value
    return objeto.orden[key] 
}


export const  dimensionesMinimas = (obj1, obj2) => {
    // Las posibles combinaciones de dimensiones
    let combinaciones = [
        {
            ancho: Math.max(obj1.ancho, obj2.ancho),
            alto: obj1.alto + obj2.alto,
            largo: Math.max(obj1.largo, obj2.largo)
        },
        {
            ancho: obj1.ancho + obj2.ancho,
            alto: Math.max(obj1.alto, obj2.alto),
            largo: Math.max(obj1.largo, obj2.largo)
        },
        {
            ancho: Math.max(obj1.ancho, obj2.ancho),
            alto: Math.max(obj1.alto, obj2.alto),
            largo: obj1.largo + obj2.largo
        }
    ];

    // Función para calcular el volumen
    const calcularVolumen = (dim) => dim.ancho * dim.alto * dim.largo;

    // Encontrar la combinación con el menor volumen
    let minVolumen = calcularVolumen(combinaciones[0]);
    let minDimensiones = combinaciones[0];

    for (let i = 1; i < combinaciones.length; i++) {
        let volumenActual = calcularVolumen(combinaciones[i]);
        if (volumenActual < minVolumen) {
            minVolumen = volumenActual;
            minDimensiones = combinaciones[i];
        }
    }

    return minDimensiones;
}

export const calcularOrdenRetrasada = (o) => {
    const hoy = DateTime.now() 
    if(o.anulado === true) return false
            if(!o.fecha_finalizado){
                // const dia_creacion                       = DateTime.fromISO(o.createdAt, { setZone: false }).setZone('utc').day
                // if(dia_creacion !== num_dia) return false

                const parseada = DateTime.fromISO(o.fecha_max_entrega).toUTC()
                if(parseada < hoy) return true
            }
            
            if(!o.fecha_max_entrega) return false

            const fecha_maximo_entrega      = DateTime.fromISO(o.fecha_max_entrega).endOf('day').toUTC()
            const fecha_finalizado          = DateTime.fromISO(o.fecha_finalizado, { zone: timezone }).startOf('day')
            
            if(o.finalizado === true){
                if(!o.fecha_finalizado) return false
                if(fecha_finalizado <= fecha_maximo_entrega) return false
            }
            if(fecha_maximo_entrega < hoy) return true
            return false
}


export const calcularCompromiso = (datos) => {
    const a_tiempo = datos.filter(o => !calcularOrdenRetrasada(o))

    const retrasadas = datos.filter(o => calcularOrdenRetrasada(o))
    return {
        a_tiempo,
        retrasadas
    }
}

export const segmentarEstadosOrdenes = (datos) => {
    if(!Array.isArray(datos)) return []
    let estados = []
    for( const dato of datos ){
        let codigo_estado = ''
        let titulo = 'Sin gestión'
        let color = 'gray'

        if(dato.estado_entrega) if(typeof dato.estado_entrega === 'object'){
            codigo_estado = dato.estado_entrega.codigo_estado
            titulo = dato.estado_entrega.titulo
            color = dato.estado_entrega.color
        }

            if(!codigo_estado){
                const i = estados.findIndex(e => e.codigo_estado === 'sin_estado')
                if(i > -1){
                    estados[i].ordenes.push(dato)
                } else {
                    estados.push({
                        color: "gray",
                        title: "Sin gestión",
                        codigo_estado: 'sin_estado',
                        // tipo_servicio: dato.tiposervicio,
                        ordenes: [dato]
                    })
                }
            } else {
                const i = estados.findIndex(e => e.codigo_estado === codigo_estado)
                if(i > -1){
                    estados[i].ordenes.push(dato)
                } else {
                    estados.push({
                        codigo_estado,
                        title: titulo,
                        color: color,
                        // tipo_servicio: dato.tiposervicio,
                        ordenes: [dato]
                    })
                }
            }
        
    }
}

export const calcularEstadoPicking = (productos) => {
    console.log({ productos })
    let estado = 'NO APLICA'
    if(productos.length < 1) return estado
    estado = "PENDIENTE"
    const cantidad = productos.length
    const cantidad_pickeada = productos.filter(p => p.cantidad_pickeada > 0).length
    const pickeados_listos = productos.filter(p => p.quantity <= p.cantidad_pickeada ).length
    if(cantidad_pickeada > 0) estado = 'EN PROCESO'
    if(pickeados_listos >= cantidad) estado = 'LISTO'
    return estado
}