const { url_images } = require("../global/data")

const debug = false

const datos = {
    urlapi: debug === true ? 'http://localhost:5001/private/1.0' : 'https://lastmile.delpasystem.com/private/1.0',
    url_front_produccion: debug === true ? 'http://localhost:3000' : 'https://ultimamilla.delpasystem.com',
    key_local_storage_user: 'lm_delpa_user',
    key_local_storage_config: 'lm_delpa_config',
    key_local_storage_permissions: 'lm_delpa_access',
    pagina: 20,
    version_app: 7.5,
    tipos_direccion:['geocode'],
    mapbox_token: "pk.eyJ1IjoiY2FybG9zZGVscGEiLCJhIjoiY2xreTBqeDQwMHphcjNrbzJzbW4weTMweiJ9._pOF3JQBp_uT78K0B1KlbA",
    maps_key: "AIzaSyBVfuPv1M8VqoDLy_wXNX3KGMMfgi-OIRM",
    timezone: "America/Santiago",
    iconSize: 30,
    iconSizeMedium: 20,
    configuracion_visual: {
        grafico_vehiculo_proveedor: true,
        grafico_general: true,
        eventos_conductores: true,
    },
    interfaz:{
        tamano_letra_tabla: 13,
        tabla_cabecera:{
            background: "#e0e0e0", 
            border: "none"
        },
        tabla_cabecera_texto: {
            fontSize: 13,
            color:"#616161"
        },
    },
    configs3: {
        dirName: "lastmile/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    },
    mercadolibre_config:{
        client_id: "8686228924463533",
        redirect_uri: "https://lastmile.delpasystem.com/integraciones/mercadolibre/login"
    },
    pasarelas: [
        { name: "transbank", description: "Webpay One Click", logo: `${url_images}/payments_methods/oneclick.png` },
        { name: "mercadopago", description: "Mercado Pago", logo: `${url_images}/payments_methods/mercado-pago.png` },
    ],
    colores: {
        0: "#0028ff",  // Gris Pizarra
        1: "#ff8300",  // Rosado Pálido
        2: "#b40b6a",  // Verde Oliva
        3: "#0ba4b4",  // Acero Azul
        4: "#b40b0b",  // Bronceado
        5: "#b4920b",  // Turquesa Oscuro
        6: "#50b40b",  // Verde Oscuro Oliva
        7: "#0b84b4",  // Verde Mar Oscuro
        8: "#5f0bb4",  // Lila Oscuro
        9: "#8B0000",  // Rojo Oscuro
        10: "#E9967A", // Salmón Oscuro
        11: "#483D8B", // Azul Pizarra Oscuro
        12: "#2F4F4F", // Verde Pizarra Oscuro
        13: "#00CED1", // Turquesa Oscuro
        14: "#9400D3", // Violeta Oscuro
        15: "#FF8C00", // Naranja Oscuro
        16: "#696969", // Gris Oscuro
        17: "#1E90FF", // Azul Dodger
        18: "#B22222", // Ladrillo Refractario
        19: "#FFD700", // Dorado
        20: "#ADFF2F", // Verde Amarillo
        21: "#F0E68C", // Caqui
        22: "#E6E6FA", // Lavanda
        23: "#FFF0F5", // Lavanda Rosada
        24: "#7B68EE", // Azul Medio Pizarra
        25: "#6A5ACD", // Azul Pizarra
        26: "#20B2AA", // Verde Mar Claro
        27: "#778899", // Gris Pizarra Claro
        28: "#B0C4DE", // Acero Azul Claro
        29: "#FFFFE0", // Amarillo Claro
        30: "#00FA9A", // Verde Primavera Medio
        31: "#48D1CC", // Turquesa Medio
        32: "#C71585", // Rojo Violeta Medio
        33: "#191970", // Azul Marino Medianoche
        34: "#F5FFFA", // Menta Crema
        35: "#FFE4E1", // Rosado Místico
        36: "#FFDEAD", // Blanquecino Navajo
        37: "#FFDAB9", // Melocotón
        38: "#CD853F", // Perú
        39: "#FFC0CB", // Rosado
        40: "#DDA0DD", // Ciruela
        41: "#B0E0E6", // Azul Polvo
        42: "#800080", // Púrpura
        43: "#663399", // Rebecca Púrpura
        44: "#BA55D3", // Orquídea Medio
        45: "#9370DB", // Púrpura Medio
        46: "#3CB371", // Verde Mar Medio
        47: "#7FFF00", // Verde Primavera
        48: "#DB7093", // Rosa Pálido
        49: "#FFB6C1"  // Rosado Claro
    }  
}

module.exports = datos