import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, ButtonGroup, Badge, Form, Table, Spinner } from 'react-bootstrap'
import data, { urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';
import { PiWarehouseDuotone } from 'react-icons/pi';
import { LiaRouteSolid } from 'react-icons/lia';
import { modulo, types } from './data';
import { LuTicket } from 'react-icons/lu';
import SelectorRangoFechas from '../../general/selector_rango_fechas';
import { IoFilter, IoFilterCircleOutline, IoTrash } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';
import { fechaATextoSimple, fechaATextoSimpleCorto, fechaUTCATexto } from '../../../lib/helpers/helpers';
import { useQuery } from '../../../lib/helpers/router';
import backend_data from './data'
import ModalListadoOrdenes from '../modal_listado';
import { obtenerFechaHoraZonaHorariaLocal } from '../../../lib/helpers/dates';
import { FaRegFileExcel } from 'react-icons/fa';
import { obtenerRangos } from '../../../lib/helpers/opcionesfecha';
import { DateTime } from 'luxon';
import datos from '../../../lib/backend/data';

const Drivers = (props) => {
    const {
        id_orden,
        condicion_default
    } = props
    const initialDate = {
        startDate: DateTime.now().startOf("day").minus({ weeks: 4 }).toJSDate(),
        endDate: DateTime.now().toJSDate(),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtro, setFiltro ] = useState({})
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ limitPage, setLimitPage ] = useState(datos.pagina)
    const { desde, hasta } = obtenerRangos(initialDate.startDate, initialDate.endDate)
    const condicion_busqueda_ = { createdAt: { $gte: desde, $lte: hasta } }
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : { })
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const [selectAll, setSelectAll] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [showModal, setShowModal] = useState(false);
    let query = useQuery()
    const id_ticket = query.get("id")

    // Boton de crear ruta nueva
    const [show, setShow] = useState(false); 
    const [isActive, setIsActive] = useState(false);
    
    

    const obtenerProveedores = async (page, query, page_quantity)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda

        let limite_paginacion = page_quantity ? page_quantity : limitPage

        return fetch(`${urlapi}/${modulo}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`,
                limit: limite_paginacion
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(id)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        setShow(!show)
        setIsActive(true)
        return fetch(`${urlapi}/${modulo}/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
                handleShowModal()
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeFiltro = (key, val) => {
        return setFiltro(prev => {
            let actual = {...prev}
            if(!val) delete actual[key]
            else actual[key] = val
            return actual
        })
    }
    

    const filtrar = (replaceFilter) => {
        setOpenModal(false)
        let fields = [ 'type' ]
        const actual = {...condicion_busqueda}
        for( const field of fields ){
            delete actual[field]
        }

        const filtro_seleccionado = replaceFilter ? replaceFilter : filtro
        if(replaceFilter) setFiltro(replaceFilter)

        let cond = { ...actual, ...filtro_seleccionado }

        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        cond.createdAt = { $gte: desde, $lte: hasta }

        setCondicionBusqueda(cond)
        obtenerProveedores(1, cond)
    }

    const cambiarRangoFecha = (item) => {
        setSelectionRange(item.selection)
      }

    const modalFiltro = () => {

        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    {/* <div className='mb-3'><SelectorRangoFechas /></div> */}
                    <label className='d-block form-control-label'>Tipo</label>
                    <select className="form-control shadow-sm" name="type" value={filtro?.type ? filtro?.type : ""} onChange={(e) => handleChangeFiltro('type', e.target.value )} >
                        <option value="">Selecciona</option>
                        { types.map((type,i) => <option key={`ty-${i}`} value={type.value}>{type.label}</option>) }
                    </select>
                </Col>
                <Col xs={12} className="mb-2">
                    <div className='mb-3 mt-3'><SelectorRangoFechas default_range_dates={selectionRange} onChange={(item) => cambiarRangoFecha(item)} /></div>
                </Col>
                <Col md={12}>
                    <ButtonGroup>
                    <Button size="sm" variant="success" onClick={() => filtrar()} >FILTRAR</Button>
                    <Button size="sm" variant="light" onClick={() => filtrar({})} ><MdClear /> LIMPIAR FILTROS</Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const handleChangeCantidadPagina = (e) => {
        const { value } = e.target
        setLimitPage(value)
        obtenerProveedores(null, null, value)
    }

    const selectorCantidades = () => {
        return <select className='ml-3' style={{ fontSize: 13, padding: 5 }} value={limitPage} onChange={handleChangeCantidadPagina} >
                <option value="20">MOSTRAR 20</option>
                <option value="50">MOSTRAR 50</option>
                <option value="100">MOSTRAR 100</option>
            </select>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        let cond =  condicion_default ? condicion_default : condicion_busqueda
        if(id_orden){
            cond.id_orden = id_orden
            delete cond.createdAt
        }
        
        if(id_ticket){
            onClickProveedor(id_ticket)
        }
        obtenerProveedores(1, cond)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()

        const tipos = {}
        types.map(t => tipos[t.value] = t.label)
        const string_length = 50

        const handleSelectAll = () => {
            setSelectAll(!selectAll);
            if (!selectAll) {
                setSelectedTicket(registros.map(orden => orden._id));
            } else {
                setSelectedTicket([]);
            }
        }
    
        const handleSelectTicket = (id) => {
            if (selectedTicket.includes(id)) {
                setSelectedTicket(selectedTicket.filter(ticketId => ticketId !== id));
                setSelectAll(false);
            } else {
                setSelectedTicket([...selectedTicket, id]);
                if (selectedTicket.length + 1 === registros.length) {
                    setSelectAll(true);
                }
            }
        }

        if(registros.length < 1) return  <div className='text-center'>
            <h5 style={{fontWeight:900}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        return <div>
            <Row>
            <Col md={6}>
            <h6>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b> {selectorCantidades()}</h6>
            {paginacion(data.pagina, total)}
            </Col>
            <Col md={6} className="d-flex justify-content-end align-items-end">
                {(selectAll || selectedTicket.length > 1) &&
                    <Button variant="success" className=" mb-2 w-50 shadow-sm" style={{fontSize:14}}>
                        Responder Masivo
                    </Button>
                }
                {selectedTicket.length === 1 && !selectAll && 
                    <Button variant="success" className=" mb-2 w-50 shadow-sm" style={{fontSize:14}}>
                        Responder
                    </Button>
                }
            </Col>
            </Row>

            <Card className='shadow-sm border-0 p-0 mb-3'>
                <div className='table-responsive'>
            <Table responsive="md" bordered hover className='mb-0 align-middle'>
                <thead className='bg-secondary text-white'>
                    <tr>
                        <th>
                            <Form.Check type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                        </th>                 
                        <th></th>
                        <th>CREADO</th>
                        <th>CREADOR</th>
                        <th>CONDICIÓN</th>
                        <th>REFERENCIA</th>
                        <th>DESTINATARIO</th>
                        <th>TICKET</th>
                        <th>DESCRIPCION</th>
                        <th>USUARIO MENSAJE</th>
                        <th>ULTIMO MENSAJE</th>
                        <th>TIPO</th>
                        <th>TRACKING</th>
                        <th>ESTADO</th>
                    </tr>
                </thead>
                <tbody>
                {
                    registros.map(orden => {
                        let seleccionado = false
                        if(idSeleccionado === orden._id) seleccionado = true
                        return <tr key={orden._id} className="align-middle">
                            <td>
                                <Form.Check type="checkbox" checked={selectedTicket.includes(orden._id)} onChange={() => handleSelectTicket(orden._id)} />
                            </td>
                            <td className='p-3' ><Button disabled={loadingDetalle} variant='light' size="sm" onClick={() => onClickProveedor(orden._id)}>
                            { loadingDetalle === orden._id ? <Spinner size="sm" animation='border' /> : "VER" }
                            </Button> </td>
                            <td className='p-3' >{ fechaATextoSimpleCorto(orden.createdAt)}</td>
                            <td>{orden.creador?.nombres}</td>
                            <td>{backend_data.status[orden.status]}</td>
                            <td>{orden.order?.pedido}</td>
                            <td>{orden.order?.orden?.billing?.first_name}</td>
                            <td className='p-3' ><LuTicket /> {orden.id}</td>
                            <td className='p-3' >{orden.titulo.substring(0,string_length)} {orden.titulo.length >= string_length ? "..." : ""}</td>
                            <td className='p-3' >{orden.ultimo_mensaje ? orden.ultimo_mensaje?.creador?.nombres : "Sin información" }</td>
                            <td className='p-3' >{orden.ultimo_mensaje ? fechaUTCATexto(orden.ultimo_mensaje?.createdAt) : "Sin información" }</td>
                            <td className='p-3' >
                                <Badge variant="secondary" style={{ textTransform: "uppercase" }} className="mb-0">{tipos[orden.type]}</Badge>
                            </td>
                            <td>{orden.order ? <ModalListadoOrdenes title="TRACKING" condicion={{ _id: orden.order._id }} /> : "Sin información"}</td>
                            <td className='p-3' >
                                <Badge variant={orden.open ? "success" : "dark"} style={{ textTransform: "uppercase" }} className="mb-0">{orden.open ? "ABIERTO" : "CERRADO"}</Badge>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </Table>
            </div>
            </Card>
            {/* <BoxList proveedor={orden} seleccionado={seleccionado} onClickProveedor={(id) => onClickProveedor(id)} /> */}
        </div>
    }

    const handleSubmitPalabra = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda } }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }
    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProveedores(1, {})
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setShow(!show)
            setIsActive(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onProveedorNuevo = (data) => {
        proveedores.unshift(data)
        setProveedores(prev => [...[], ...proveedores])
        onClickProveedor(data._id)
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const borrarFiltros = () => {
        setFiltro({})
        setCondicionBusqueda({})
        setPagina(1)
        obtenerProveedores(1, {})
    }

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const descargarReporte = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reporte/ordenes/tickets`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `tickets-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

 
    return <div className='text-primary'>
        {modalFiltro()}
        <Row className='mb-2'>
            <Col md={12}>
            <h3 className='pt-2' style={{fontWeight:900}}><LuTicket /> Conductores
                        {/* {isActive && <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Crear nuevo</Tooltip>
                            }
                            >
                            <i className="fa-solid text-primary hover fa-circle-plus ml-2" onClick={()=>crearProveedor()} ></i>
                          </OverlayTrigger>} */}
              
            </h3>
            <Row>
                <Col md={12} className='d-flex'>
                <DetailFull
                        id_orden={id_orden}
                        onFieldDeleted={(id) => onFieldDeleted(id)}
                        crear={crearNuevo} 
                        onProveedorNuevo={(data) => onProveedorNuevo(data)} 
                        token={token} 
                        proveedor={vehiculoSeleccionado} 
                        onProveedorGuardado={(data) => onProveedorGuardado(data)}
                    />
                <Button size="sm" variant="light" className='mb-2 mr-2 text-primary' style={{fontWeight:700}} onClick={() => setOpenModal(true)}><IoFilter /> FILTROS</Button>
                <Button size="sm" variant="light" className='mb-2 mr-2 text-primary' disabled={loadingExcel} style={{fontWeight:700}} onClick={() => descargarReporte()}><FaRegFileExcel /> EXPORTAR</Button>
                {/* <Button size="sm" variant="light" className='mb-2 mr-2 text-primary' style={{fontWeight:700}} onClick={() => borrarFiltros()}><IoTrash /> BORRAR FILTROS</Button> */}
                </Col>
            </Row>
                <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control shadow-sm' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                </Row>

            <hr className='mt-0'/>
            </Col>
            <Col md={6} className='d-flex align-items-center'>
            
            </Col>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton className='pb-0'>
                    <Modal.Title className='text-primary' style={{fontWeight:900}}>DETALLES TICKET</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <hr className='mt-0 mb-3' />
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull 
                        id_orden={id_orden}
                        onFieldDeleted={(id) => onFieldDeleted(id)}
                        crear={crearNuevo} 
                        onProveedorNuevo={(data) => onProveedorNuevo(data)} 
                        token={token} 
                        proveedor={vehiculoSeleccionado} 
                        onProveedorGuardado={(data) => onProveedorGuardado(data)} 
                        />
                    }
                </Modal.Body>
            </Modal>

            </Row>
            {tabla_registros(proveedores)}
    </div>

}

export default Drivers