import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { urlapi } from "../../lib/backend/data"
import { Col, Row } from "react-bootstrap"

const EstadoExpedicionRuta = (props) => {
    const {
        id_ruta
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState('')
    const [ datos, setDatos ] = useState(null)

    const obtenerDetalles = async () => {
        if(!id_ruta) return setLoading(false)
        return fetch(`${urlapi}/bodega/expediciones/estado-ruta?id=${id_ruta}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                setError('Hubo un error de servidor')
              } else if(pros.errorMessage){
                setError("Error al procesar la información")
              } else if(pros._id){
                  setDatos(pros)
              }
              return setLoading(false)
          })
          .catch(async error => {
                setError('Error al obtener los datos')
                return setLoading(false)
          })
    }

    useEffect(() => {
        obtenerDetalles()
    }, [])

    if(loading) return <div>
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
    </div>

    if(!datos) return false
    return <div>
                <h4>Expedición</h4>
        <Row>
            <Col>
            </Col>
        </Row>
    </div>
}

export default EstadoExpedicionRuta