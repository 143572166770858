import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Modal, Badge } from 'react-bootstrap'
import data, { maps_key, tipos_direccion } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { host } from '../../lib/global/data';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { corregirTelefono, validateEmail } from '../../lib/helpers/helpers';
import BuscadorRutas from '../rutas/buscador';
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { url_images } from "../../lib/global/data";
import { AiTwotoneDelete } from 'react-icons/ai'
import SelectorBodega from '../bodega/bodegas/selector_bodega';
import SelectorTipoAsignacion from '../general/selector_tipo_asignacion';
import { calcularPesoVolumetrico, obtenerGeoDatosGoogle } from '../../lib/helpers/pedidos/pedidos';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import TiposServicioSelector from './tipos_servicio/selector';
import { MdOutlinePlace } from 'react-icons/md';
import { GrDocumentText } from 'react-icons/gr';
import DireccionesMapa from '../../components/Zonas/direcciones/direcciones_mapa';
import { rutas } from '../../lib/routes/routes';
import { confirmAlert } from "react-confirm-alert"
import { urlapi } from "../../lib/backend/data"
import SelectorBodegaDestino from '../bodega/bodegas/selector_bodega_destino';
import VerifyModelBusiness from '../model_business/verify_model_business';
import { default_proveedores } from '../proveedores_transporte/data';
import { dimensionesMinimasArreglo, formatoMoneda } from '../../lib/helpers/main';
import { FaCheckCircle } from 'react-icons/fa';
import PaymentMethods from '../financiero/listado_metodos';
import ModalProductos from '../productos/modal_productos';
import SelectorCuentasFacturacion from '../cuentas-facturacion/selector';
// import TablaProductosNuevo from './tabla_productosnuevo';

const CrearOrden = (props) => {
    //modalFecha
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(), 1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ orden, setOrden ] = useState({ tipo_seleccion: "simple" })
    const [movilesBodegas, setMovilesBodegas] = useState([])
    const [emailsBodegas, setEmailsBodegas] = useState([])
    const [ codigoProveedor, setCodigoProveedor ] = useState("")
    const [ metodoPagoSeleccionado, seleccionarMetodoPago ] = useState(false)
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ showModalCotizacion, setShowModalCotizacion ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ showModalError, setShowModalError ] = useState(false)
    const [ modalErrorMessage, setModalErrorMessage ] = useState('')
    const [ geojson, setGeoJson] = useState(false)
    const [ geometrias, setGeometrias] = useState([])
    const [ loadingCotizacion, setLoadingCotizacion] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ destino, setDestino ] = useState(false)
    const [ descripcion, setDescripcion ] = useState('')
    const [ referenciaValida, setReferenciaValida ] = useState(false)
    const [ estadoReferencia, setEstadoReferencia ] = useState('')
    const [ loadingEstadoReferencia, setLoadingEstadoReferencia ] = useState(false)
    const [ bodegaOrigen, seleccionarBodegaOrigen ] = useState(false)
    const [ bodegaDestino, seleccionarBodegaDestino ] = useState(false)
    const [ activarRastreo, setActivarRastreo ] = useState(false)
    const [botonMoviles, setBotonMoviles] = useState(false)
    const [botonEmails, setBotonEmails] = useState(false)
    const [mostrarSelectorMoviles, setMostrarSelectorMoviles] = useState(false)
    const [mostrarSelectorEmails, setMostrarSelectorEmails] = useState(false)

    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ productos, setProductos ] = useState([])
    const dispatch = useDispatch()
    const [seleccion, setSeleccion] = useState('simple');
    const [ loading, setLoading ] = useState(false)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const [ posicion, setPosicion ] = useState(0)
    const [statusBoton, setStatusBoton ] = useState(false)
    
    const handleChangeSeleccion = (event) => {
        const val = event.target.value
        if(val === "simple"){
            setProductos([])
        }
        setSeleccion(val);
        setOrden(prev => {
            const actual = {...prev}
            actual.tipo_seleccion = val
            return actual
        })
    }    

    useEffect(() => {
        // obtenerProveedores()
    },[bodegaOrigen, orden])

    const emitirMensajeError = (message) => {
        setModalErrorMessage(message)
        return setShowModalError(true)
    }

    const cotizarOrden = async (origen, destino, medidas) => {
        setLoadingCotizacion(true)
        setShowModalCotizacion(true)
        return fetch(`${data.urlapi}/pricing/quotation/external/singular`,{
            method:'POST',
            body: JSON.stringify({
                origen, destino, medidas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                const i = res.findIndex(e => e.provider === orden.courrier)
                if(i > -1){
                    if(res[i].tarifas) if(Array.isArray(res[i].tarifas)){
                        const tarifas = res[i].tarifas
                        if(tarifas.length > 0){
                            setOrden(prev => {
                                let actual = {...prev}
                                actual.tarifa = tarifas[0]
                                return {...{}, ...actual}
                            })
                        }
                    }
                }
            }
            return setLoadingCotizacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCotizacion(false)
        })
    }


    const crearNuevaRuta = async (confirm) => {

        const requeridos = [
            { value: "pedido", label: "Referencia" },
            { value: "destinatario", label: "Destinatario" },
            // { value: "direccion", label: "Dirección de destino" },
            { value: "direccion_2", label: "Observaciones de la dirección" },
            // { value: "ruta", label: "Número de Ruta" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if (!orden[campo.value]) faltantes.push(campo.label)
            return true
        })
        if (faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        const email = validateEmail(orden.email ? orden.email.toString() : '')
        if (!email) {
            return toast.error('Email inválido')
        }

        const geo_informacion = obtenerGeoDatosGoogle(bodegaOrigen ? bodegaOrigen.geodata : {} )
        const geo_informacion_destino = obtenerGeoDatosGoogle(bodegaDestino ? bodegaDestino.geodata : {} )

        if(orden.telefono) orden.telefono = corregirTelefono(orden.telefono.toString())
        orden.fecha_min_entrega = selectionRange.startDate
        orden.fecha_max_entrega = selectionRange.endDate
        orden.productos = productos
        orden.orden = {}

        if(bodegaOrigen){

            let importar_datos = {
                level1: geo_informacion.level1 ?  geo_informacion.level1 : "",
                level2: geo_informacion.level2 ?  geo_informacion.level2 : "",
                pais: geo_informacion.pais ? geo_informacion.pais : "",
                level3: geo_informacion.level3 ? geo_informacion.level3 : "",
                address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : "",
                formatted_address: geo_informacion.formatted_address ? geo_informacion.formatted_address : "",
                lat: geo_informacion.lat,
                lng: geo_informacion.lng
            }
            
            if(typeof bodegaOrigen._id === "string"){
                importar_datos = {
                    ...importar_datos,
                    _id: bodegaOrigen._id, 
                    tipo_lugar: "bodega",
                    first_name: bodegaOrigen.titulo ? bodegaOrigen.titulo : ""
                } 
            }

            if(bodegaOrigen.location){
                if(typeof bodegaOrigen.location === "object"){
                    if(bodegaOrigen.location.coordinates){
                        if(bodegaOrigen.location.coordinates.length > 1){
                            orden.location = bodegaOrigen.location
                            importar_datos.lat = bodegaOrigen.location.coordinates[1]
                            importar_datos.lng = bodegaOrigen.location.coordinates[0]
                        }
                    }
                }
                
            }
            orden.orden.geo_datos_origen = importar_datos
        }
        // orden.orden = {
        //         geo_datos_origen: {
        //                 level1: geo_informacion.level1 ?  geo_informacion.level1 : "",
        //                 level2: geo_informacion.level2 ?  geo_informacion.level2 : "",
        //                 pais: geo_informacion.pais ? geo_informacion.pais : "",
        //                 level3: geo_informacion.level3 ? geo_informacion.level3 : "",
        //                 address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : ""},
        //         origen: {
        //                     _id: bodegaOrigen._id ? bodegaOrigen._id : "",
        //                     address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : "",
        //                     first_name: bodegaOrigen.titulo ? bodegaOrigen.titulo : "",
        //                     ...geo_informacion
        //                 }
           
        // }
        
     
        // if(destino) if(typeof destino === "object") orden.orden.geo_datos = destino
       
        if(bodegaDestino){
            orden.direccion = bodegaDestino.direccion
            let importar_datos_destino = {
                level1: geo_informacion_destino.level1 ?  geo_informacion_destino.level1 : "",
                level2: geo_informacion_destino.level2 ?  geo_informacion_destino.level2 : "",
                pais: geo_informacion_destino.pais ? geo_informacion_destino.pais : "",
                level3: geo_informacion_destino.level3 ? geo_informacion_destino.level3 : "",
                address_1: bodegaDestino.direccion ? bodegaDestino.direccion : "",
                formatted_address: geo_informacion_destino.formatted_address ? geo_informacion_destino.formatted_address : "",
                lat: geo_informacion_destino.lat,
                lng: geo_informacion_destino.lng
            }
            
            if(typeof bodegaDestino._id === "string"){
                orden.id_cliente = bodegaDestino.id_cliente
                importar_datos_destino = {
                    ...importar_datos_destino,
                    _id: bodegaDestino._id, 
                    tipo_lugar: "bodega",
                    first_name: bodegaDestino.titulo ? bodegaDestino.titulo : ""
                } 
            }
            if(bodegaDestino.location){
                if(typeof bodegaDestino.location === "object"){
                    if(bodegaDestino.location.coordinates){
                        if(bodegaDestino.location.coordinates.length > 1){
                            orden.location = bodegaDestino.location
                            importar_datos_destino.lat = bodegaDestino.location.coordinates[1]
                            importar_datos_destino.lng = bodegaDestino.location.coordinates[0]
                        }
                    }
                }
                
            }
            orden.orden.geo_datos = importar_datos_destino
        }
        
 
        const instancia_orden = JSON.parse( JSON.stringify( orden ))
        if(activarRastreo){
            if(geometrias.length < 1 || !geojson) return emitirMensajeError(`Activaste el rastreo pero aún no confirmas la ruta a rastrear, confirmala y vuelve a intentarlo`)
            instancia_orden.geometrias = geometrias
            if(geojson) if(typeof geojson === "object") instancia_orden.geocercas = geojson.features
            instancia_orden.rastrear = activarRastreo
        }

        const calculo_medidas = calcularPesoVolumetrico(instancia_orden.productos)
        const peso = calculo_medidas.default_weight
        let medidas = dimensionesMinimasArreglo(instancia_orden.productos)
        medidas.peso = peso * 1000 // LLEVAR A GRAMOS
        let datos_medidas = {
            volumen: calculo_medidas.default_vol
        }
        Object.keys(medidas).map(key => {
            datos_medidas[key] = parseFloat(medidas[key])
            return true
        })

        if(seleccion === "simple"){
            datos_medidas.peso = parseFloat(instancia_orden.peso) * 1000
            datos_medidas.volumen = parseFloat(instancia_orden.volumen)
        }
        instancia_orden.medidas = datos_medidas

        
        if(instancia_orden.courrier){
            if(!confirm) return cotizarOrden(bodegaOrigen?.geodata, bodegaDestino?.geodata, datos_medidas)
        }
        
        delete instancia_orden.courrier
        instancia_orden.metodo_pago = metodoPagoSeleccionado
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/ordenes`, {
            method: 'POST',
            body: JSON.stringify(instancia_orden),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingCreacion(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingCreacion(false)
                } else if (res._id) {
                    toast.success('Creada exitosamente')
                    setShowModalCotizacion(false)
                    return setTimeout(() => {
                        window.location = `/${rutas.ordenes.slug}/${res._id}`
                    }, 1000)
                }
                return setLoadingCreacion(false)
            })
            .catch(error => {
                toast.error(error.message)
                return setLoadingCreacion(false)
            })
    }

    const handleChangeDescripcion = (e) => {
        const { value } = e.target
        return setDescripcion(value)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const mostrarEstadoReferencia = () => {
        if (loadingEstadoReferencia === true) return <p style={{ position: 'absolute', right: 30, bottom: 13, fontSize: 10 }}><Spinner size="sm" animation='border' /></p>
        return false
    }

    const agregarProducto = (data) => {
        return setProductos(prev => {
            const arr = [...prev]
            const numero = posicion + 1
            const producto = {
                id: numero,
                ...data,
                quantity: 1
            } 
            arr.push(producto)   
            setLoading(false)   
            setPosicion(posicion + 1)      
            return ([...arr])
        })
    }
       
    const agregarProductoCrear = () => {
        setLoading(true)
       return setProductos(prev => {
        const arr = [...prev]
        const numero = posicion + 1
        const producto = {
            id: numero,
            description: "",
            codigo_item: "",
            peso: 0,
            alto: 0,
            ancho: 0,
            largo: 0,
            price: 0,
            quantity: 1
        } 
        arr.push(producto)   
        setLoading(false)   
        setPosicion(posicion + 1)      
        return ([...arr])
       })      
      }
  

    const mostrarTextoEstadoReferencia = () => {
        if (referenciaValida === true) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-success'><i className="fa-solid fa-circle-check"></i> {estadoReferencia}</p>
        if (estadoReferencia) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'><i className="fa-solid fa-triangle-exclamation"></i> {estadoReferencia}</p>
        return <label className='form-control-label d-block'>Referencia *</label>
    }

    const handleChangeOrden = (e) => {
        const { name, value } = e.target
        return setOrden(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const onChangeTipoServicio = (e) => {
        return setOrden(prev => {
            let actual = {...prev}
            actual.tiposervicio = e?._id
            return {...{}, ...actual}
        })
    }

    const onSelectRoute = (data) => {
        console.log(data)
        const { coordenadas, geometries } = data
        setGeometrias(prev => [...[], ...geometries])
        // if(props.onChangeGeometries) props.onChangeGeometries(nuevas_geometrias)
        const nuevo_arreglo = coordenadas
        const nueva_collection = {
            features: nuevo_arreglo
        }
        setGeoJson(nueva_collection)
      }

      
    const handleChangePeso = (e) => {
        const { name, value } = e.target
        const id =  parseInt(name)
        const i = productos.findIndex(campo => campo.id === id)
        if(i < 0) return false
        productos[i].peso = typeof productos[i].peso == NaN ? "" : parseInt(value)
        if(productos[i].peso === "" ) productos[i].peso = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeAlto = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].alto = value
        if(productos[i].alto === "" ) productos[i].alto = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeLargo = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].largo = value
        if(productos[i].largo === "" ) productos[i].largo = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeAncho = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].ancho = value
        if(productos[i].ancho === "" ) productos[i].ancho = 0
        return setProductos([...[], ...productos])
    }
    const handleChangeDescription = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].description = value
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeQuantity = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].quantity = value
        if(productos[i].quantity === "" ) productos[i].quantity = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangePrice = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].price = value
        if(productos[i].price === "" ) productos[i].price = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeCodigo = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo.id == id)
        if(i < 0) return false
        productos[i].codigo_item = value
        console.log(productos)
        return setProductos([...[], ...productos])
    }

    const solicitarEliminar = (pos) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta orden, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminar(pos)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const eliminar = (pos) => {        
        return setProductos(prev => {
            let actual = prev
            actual.splice(pos,1)
            return ([...actual])
        })
    }

    const validarReferencia = async () => {
        if (!orden.pedido) {
            setReferenciaValida(false)
            return setEstadoReferencia('Referencia inválida')
        }
        setLoadingEstadoReferencia(true)
        return fetch(`${data.urlapi}/ordenes/validar/referencia?referencia=${orden.pedido}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingEstadoReferencia(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingEstadoReferencia(false)
                } else if (typeof res === 'object') {
                    setReferenciaValida(res.valido === true ? true : false)
                    setEstadoReferencia(res.valido === true ? 'Válida' : 'Inválida')
                }
                return setLoadingEstadoReferencia(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingEstadoReferencia(false)
            })
    }

    const onChangeRuta = (e) => {
        orden.ruta = e.value
        return setOrden(orden)
    }

    const handleChangeAsignacion = (e) => {
        const { value } = e.target
        setCodigoProveedor('')
        return setTipoAsignacion(value)
    }

    const obtenerGeoDatos = async (bodegaOrigen) => {

        const agregar_bodega = bodegaOrigen ? bodegaOrigen.map(o => {

            const geo_informacion = obtenerGeoDatosGoogle(bodegaOrigen.geodata)

            o.origen = {
                address_1: bodegaOrigen.direccion,
                first_name: bodegaOrigen.titulo,
                ...geo_informacion
            }

            o.geo_datos_origen = {
                level1: geo_informacion.level1,
                level2: geo_informacion.level2,
                pais: geo_informacion.pais,
                level3: geo_informacion.level3,
                address_1: bodegaOrigen.direccion
            }

            if(bodegaOrigen.id_cliente) o.id_cliente = bodegaOrigen.id_cliente
            return o
        }) : bodegaOrigen

        // const iterarTarifas = iterarTarifasOrden(agregar_bodega)

        return agregar_bodega

    }

    const procesarPayloadDireccion = (place) => {
        /*
                            pais,
                            codigo_pais,
                            formatted_address,
                            level1,
                            level2,
                            level3,
                            lat,
                            lng
        */
        return {
            direccion: place.formatted_address,
            place,
            administrative_area_level_1_: place.level1,
            administrative_area_level_2: place.level2,
            administrative_area_level_3: place.level3,
            status: true,
            location: {
                type: "Point",
                coordinates: [
                    place.lng, place.lat
                ]
            }
        }
    }

    const handleCloseModalError = () => {
        setModalErrorMessage('')
        return setShowModalError(false)
    }

    const setearBodegaDestino = (data) => {
        console.log(data)
        seleccionarBodegaDestino(data)

        //como estamos cambiando de bodega limpiamos los campos telefono y email y los estados de moviles y emails adicionales
        setOrden(prev => {
            let actual = {...prev}
            actual.email = ""
            actual.telefono = ""
            return {...{}, ...actual}
        })
        setMovilesBodegas([])
        setEmailsBodegas([])
        setBotonEmails(false)
        setBotonMoviles(false)
       
        if(data.moviles_adicionales?.length > 0){
            console.log(data.moviles_adicionales)
            setMovilesBodegas(prev => {
                let moviles = [...prev]
                moviles =  data.moviles_adicionales.map(e => ({label: `${e.pais_codigo}${e.movil}`, value: `${e.pais_codigo}${e.movil}` }))
                return moviles
            })
            setMostrarSelectorMoviles(true)
        }

        if(data.movil){
            setMovilesBodegas(prev => {
                let actual = [...prev]
                const existe = actual.some(item => item.value === data.movil);

                // Si no existe, agregar el nuevo objeto
                if (!existe) {
                    let movil = ""
                    if (data.movil.startsWith("+")) {
                        // Eliminar el "+" al inicio
                           movil = data.movil.substring(1);
                      } else {movil = data.movil}
                actual.push({label: movil, value: movil})}
                return actual
            })}

        if(data.telefono){
            setMovilesBodegas(prev => {
                let actual = [...prev]
                const existe = actual.some(item => item.value === data.telefono);

                // Si no existe, agregar el nuevo objeto
                if (!existe) {
                    let telefono = ""
                    if (data.telefono.startsWith("+")) {
                        // Eliminar el "+" al inicio
                           telefono = data.telefono.substring(1);
                      } else { telefono = data.telefono}
                actual.push({label: telefono, value: telefono})}
                return actual
            })}


        if(data.emails_adicionales?.length > 0){
            setEmailsBodegas(prev => {
                let emails = prev
                emails =  data.emails_adicionales.filter(e => e.valid ).map(e => ({ label: e.email, value: e.email }));
                return emails
            })
            setMostrarSelectorEmails(true)
        }
  
        return setOrden(prev => {
            let actual = {...prev}
            // console.clear()
            console.log("orden", actual)
            actual.destinatario = data.titulo
            if(data.observaciones) actual.direccion_2 = data.observaciones
            return {...{}, ...actual}
        })
    }

    const mostrarTarifa = () => {
        if(!orden.tarifa) return <div>
            <h5><b>¡Sin tarifas encontradas!</b></h5>
        </div>

        return <div>
            <label className='form-control-label d-block mb-0'>Precio</label>
            <h5><b>{orden.tarifa?.currency_code} {formatoMoneda(orden.tarifa?.price)}</b></h5>
            <PaymentMethods avoid_create={true} onSelect={(id) => seleccionarMetodoPago(id)} />
            <Button disabled={loadingCreacion || !metodoPagoSeleccionado } size='sm' className='w-100' variant='success' onClick={() => crearNuevaRuta(true)}><FaCheckCircle /> { loadingCreacion ? "CONFIRMANDO..." : "CONFIRMAR" }</Button>
        </div>
    }

    const handleChangeSelectMovilBodega = (e) => {
        if(e === null){
            return setOrden(prev => {
                let actual = {...prev}
                actual.telefono = ""
                console.log("orden", actual)
                return {...{}, ...actual}
            })
        }

        if( e.value === "escribir_telefono"){
            setBotonMoviles(!botonMoviles)
            setMostrarSelectorMoviles(!mostrarSelectorMoviles)
            return
        }

        return setOrden(prev => {
            let actual = {...prev}
            actual.telefono = e.value
            console.log("orden", actual)
            return {...{}, ...actual}
        })   
        }

        const handleChangeSelectEmailBodega = (e) => {
            if(e === null ){
                return setOrden(prev => {
                    let actual = {...prev}
                    actual.email = ""
                    console.log("orden", actual)
                    return {...{}, ...actual}
                })
            }

            if( e.value === "escribir_email"){
                setBotonEmails(!botonEmails)
                setMostrarSelectorEmails(!mostrarSelectorEmails)
                return
            }
    
            return setOrden(prev => {
                let actual = {...prev}
                actual.email = e.value
                console.log("orden", actual)
                return {...{}, ...actual}
            })   
            }

    const mostrarMoviles = () => {
        if(movilesBodegas.length > 0 && mostrarSelectorMoviles) return <div>
                       <Select 
                    isClearable
                    style={{ marginBottom: 10 }}
                    onChange={handleChangeSelectMovilBodega}
                    loadingMessage={() => "Cargando datos"}           
                    options={[...movilesBodegas, {value: "escribir_telefono", label: "Escribir Telefono"}]}
                    defaultValue={''}
                    placeholder="Selecciona un movil"
                    noOptionsMessage={()=>'Sin opciones'}
                    />
    </div>
                    return <div> <input className='form-control mb-3 shadow-sm' autoComplete="off" name="telefono" onChange={handleChangeOrden} /></div>
    }

    const mostrarEmails = () => {
        if(emailsBodegas.length > 0 && mostrarSelectorEmails ) return <div>
            
                       <Select 
                    isClearable
                    style={{ marginBottom: 10 }}
                    onChange={handleChangeSelectEmailBodega}
                    loadingMessage={() => "Cargando datos"}           
                    options={[...emailsBodegas, , {value: "escribir_email", label: "Escribir Email"}]}
                    defaultValue={''}
                    placeholder="Selecciona un email"
                    noOptionsMessage={()=>'Sin opciones'}
                    />
    </div>
                    return  <input className='form-control mb-3 shadow-sm' name="email" onChange={handleChangeOrden} />
    }

    const handleOpcionesMoviles = () => {
        setBotonMoviles(!botonMoviles)
        setMostrarSelectorMoviles(!mostrarSelectorMoviles)
    }

    const handleOpcionesEmails = () => {
        setBotonEmails(!botonEmails)
        setMostrarSelectorEmails(!mostrarSelectorEmails)
    }

    const mostrarBotonVerMoviles = () => {
        if(!botonMoviles) return false
        return <Button className='mb-3' size="sm" onClick={() => handleOpcionesMoviles ()}>ver moviles</Button>
    }

    const mostrarBotonVerEmails = () => {
        if(!botonEmails) return false
        return <Button className='mb-3' size="sm" onClick={() => handleOpcionesEmails ()}>ver emails</Button>
    }

    const mostrarModalCotizacion = () => {

        let indexarProveedores = {}
        for( const prov of default_proveedores ){
            indexarProveedores[prov.slug] = prov
        }

        const icono = indexarProveedores[orden.courrier] ? <img style={{ width: 100 }} src={indexarProveedores[orden.courrier].logo} /> : false 
        
        return <div>
            <Modal
                        className='text-primary'
                        show={showModalCotizacion} onHide={() => {
                            setShowModalCotizacion(false)
                            seleccionarMetodoPago(false)
                        }}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ textAlign: "center" }}>Confirma la tarifa</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            { loadingCotizacion === true ? <div>
                                {icono}
                                <p><Spinner size="sm" animation='border' /> <b>Buscando tarifa...</b></p>
                            </div> : <div>
                                {icono}
                                <hr className='mb-2 mt-2' />
                                {mostrarTarifa()}
                            </div> }
                        </Modal.Body>
                    </Modal>
        </div>
    }

    const mostrarModalError = () => {

        return <div>
            <Modal
                        className='text-primary'
                        show={showModalError} onHide={handleCloseModalError}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontWeight: 900, textAlign: "center" }}>¡Atención!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='mb-2'>
                            <div className="text-center">
                                <img style={{ width: 200 }} src={`${url_images}/animations/7035809.gif`} />
                            </div>
                            {modalErrorMessage}
                        </Modal.Body>
                    </Modal>
        </div>
    }

    const mostrarProductosMantenedor = () => {
        if(seleccion === "simple"){
            return <Row>
                <Col md={2} className='pr-0'>
                    <h5 className='d-block' style={{fontWeight:900}}><i className="fa-solid fa-boxes-packing"></i> Bultos</h5>
                    </Col>
                <Col md={10} className='pl-0'><hr/></Col>
                <Col md={2}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Secos *</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" type="number" min="0" name="bultos" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={2}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Congelados</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" type="number" min="0" name="congelados" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={2}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Refrigerados</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" type="number" min="0" name="refrigerado" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={3}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Peso</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" type="number" min="0" name="peso" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={3}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Volumen</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" type="number" min="0" name="volumen" onChange={handleChangeOrden} />
                    </Col>  
            </Row>
        } else {
            return <Row>
<Col md={12} className='mb-3'>
                <Button size='sm' variant='outline-primary mb-3 mt-3' onClick={() => agregarProductoCrear()}><i className="fa-solid fa-circle-plus"></i> AGREGAR PRODUCTO</Button> 
                <ModalProductos title="AGREGAR PRODUCTO DEL MAESTRO" onSelected={(pro) => agregarProducto(pro)} />
                {   loading === true ? <Spinner animation='border' className='mt-5 ml-3' /> : 
     <div className="table-responsive">
        
        {
                    productos.map((producto,i) => {
                        return <Card className='p-3 mb-3' key={`producto-${i}`}>
                            <h4>{producto.description}</h4>
                            <Row >
                            <Col xs={12} className='mb-3'><p onClick={() => solicitarEliminar(i)} style={{ fontSize: 13, fontWeight: "bold", textTransform: "uppercase" }} className='text-danger hover mb-0'><AiTwotoneDelete  className="hover" style={{ color:"red", fontSize: 15 }} /> Remover</p></Col>
                            <Col md={8} className='mb-3'>
                                <label className='form-control-label d-block'>Descripción</label>
                                <input className="form-control" name={producto.id} onChange={handleChangeDescription}   value={producto.description} />
                            </Col>
                            <Col md={4} className='mb-3'>
                                <label className='form-control-label d-block'>SKU</label>
                                <input className="form-control" name={producto.id} onChange={handleChangeCodigo} value={producto.codigo_item} />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Ancho</label>
                                <input className="form-control" name={producto.id} onChange={handleChangeAncho} type="number" value={producto.ancho} />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Alto</label>
                                <input className="form-control" name={producto.id} onChange={handleChangeAlto} type="number" value={producto.alto} />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Largo</label>
                                <input className="form-control"  name={producto.id} onChange={handleChangeLargo}type="number"  value={producto.largo} />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Peso</label>
                                <input className="form-control" name={producto.id} onChange={handleChangePeso} type="number" value={producto.peso} />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Cantidad</label>
                                <input className="form-control" name={producto.id} onChange={handleChangeQuantity} type="number" value={producto.quantity} />
                            </Col>
                            <Col md={4} className='mb-3'>
                                <label className='form-control-label d-block'>Precio</label>
                                <input className="form-control" name={producto.id} onChange={handleChangePrice} type="number" value={producto.price} />
                            </Col>
                        </Row>
                        </Card>
                    })
        }
    </div>
}
                </Col> 
            </Row>
        }
    }

    return <div style={{background: statusBoton ? '#ffd971' : '', padding: 10, borderRadius: 10}}>
        {mostrarModalError()}
    <Row>
    <Col md={4} className="mb-3 pr-0">
                <Card className='p-2 mb-3 shadow-sm'>
                <h5 style={{fontWeight:900}}><i className="fa-regular fa-calendar-days"></i> Rango de entrega</h5>
                <p>Este es el rango de fechas en los que se podrá entregar esta orden</p>
                    <Link variant="primary" onClick={handleShow}>
                        <label className="pl-2 m-0" style={{fontWeight:700, fontSize: 14}}>Fecha inicio - Fecha tope de entrega <i className="fa-solid fa-circle-exclamation"></i></label>
                    </Link>
                    <Modal
                        className='text-primary'
                        show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontWeight: 900 }}>Rango de entrega</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='mb-2'>
                            <div className="text-center">
                                <img style={{ width: 200 }} src={`${url_images}/animations/modules/Delivery Boy.gif`} />
                            </div>
                            Rango de fecha máxima para entrega de la orden, esta fecha es utilizada para los reportes de ruta y otif..
                        </Modal.Body>
                    </Modal>
                    <DateRange
                        locale={es}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                </Card>
            </Col>
        <Col md={8}>
        <Card className=' shadow-sm py-2 px-3'>
            <h5 className='mb-0' style={{fontWeight:900}}><i className="fa-solid fa-rectangle-list"></i> Datos de la orden</h5>
            <div style={{textAlign: 'left'}}>
                <VerifyModelBusiness orden={true} token={token} handleChangeBoton={(status) => setStatusBoton(status)}/>
            </div>
            <p><i className="fa-solid fa-triangle-exclamation text-warning"></i> Los campos marcados con <b>*</b> son obligatorios.</p>
            <label className='form-control-label'>Tipo de logística</label>
            <TiposServicioSelector defaultValue={orden?.tiposervicio} onChangeValue={(data) => onChangeTipoServicio(data)} />
            <Row>
            <Col md={2} className='pr-0'><h5 className='d-block' style={{fontWeight:900}}><MdOutlinePlace size={25} /> Origen</h5></Col>
            <Col md={10} className='pl-0'><hr/></Col>
            <Col md={12}><SelectorBodega onChange={(data) => seleccionarBodegaOrigen(data)} /></Col>
            <Col md={2} className='pr-0'><h5 className='d-block' style={{fontWeight:900}}><MdOutlinePlace size={25} /> Destino</h5></Col>
            <Col md={10} className='pl-0'><hr/></Col>
            <Col md={12}>
            <SelectorBodega titular="Destino de la carga" onChange={(data) => setearBodegaDestino(data)} />
                {/* <SelectorBodegaDestino onChange={(data) => seleccionarBodegaDestino(data)} /> */}
                </Col>
                    <Col md={4} className='pr-0 mt-3'><h5 className='d-block' style={{fontWeight:900}}><GrDocumentText size={25} /> Más información</h5></Col>
                    <Col md={8} className='pl-0 mt-3'><hr/></Col>
                    <Col md={2} style={{fontWeight:700}}>
                        {mostrarTextoEstadoReferencia()}
                        {mostrarEstadoReferencia()}
                        <input className='form-control mb-3 shadow-sm' autoComplete='off' style={{ paddingRight: 10 }} name="pedido" onBlur={() => setTimeout(() => {
                            return validarReferencia()
                        }, 500)} onChange={handleChangeOrden} />
                    </Col>
                    <Col md={4}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Destinatario *</label>
                        <input className='form-control mb-3 shadow-sm' value={orden.destinatario}  name="destinatario" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={6}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Observaciones de la dirección *</label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" value={orden.direccion_2} name="direccion_2" onChange={handleChangeOrden} />
                    </Col>
                    <Col md={3}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Móvil</label>
                        {mostrarMoviles()}
                        {mostrarBotonVerMoviles()}
                    </Col>
                    <Col md={3}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Email</label>
                        {mostrarEmails()}
                        {mostrarBotonVerEmails()}
                    </Col>
                    <Col md={6}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Nota </label>
                        <input className='form-control mb-3 shadow-sm' autoComplete="off" name="nota" onChange={handleChangeOrden} />
                    </Col> 
                    
                    <Col md={12}>
                        <label className='form-control-label d-block' style={{fontWeight:700}}>Selección de productos</label>
                        <select className='form-control mb-3 shadow-sm' value={seleccion} name="tipo_seleccion" onChange={handleChangeSeleccion}>
                            <option value="simple">Simple</option>
                            <option value="avanzado">Avanzado</option>
                        </select>
                        {mostrarProductosMantenedor()}
                    </Col>
                     
                <Col md={12} className='mb-3'>
                    <BuscadorRutas token={token} onChangeValue={(e) => onChangeRuta(e)} />
                </Col>      
                <Col md={3} className='pr-0'>
                    <h5 className='d-block' style={{fontWeight:900}}><i className="fa-solid fa-boxes-packing"></i> Rastrear ruta</h5>
                </Col>
                <Col md={9} className='pl-0'><hr/></Col>      
                <Col md={12} className='mb-3'>
                    <Button size='small' variant="light" className='mb-3' style={{ fontSize: 13 }} onClick={() => setActivarRastreo(!activarRastreo)} >{ activarRastreo ? "DESACTIVAR RASTREO DE RUTA": "ACTIVAR RASTREO DE RUTA"}</Button>
                    { bodegaOrigen && bodegaDestino && activarRastreo ? <DireccionesMapa autoAsignment={true} onSelectRoute={(data) => onSelectRoute(data)} height={300} origin={bodegaOrigen} stops={[ bodegaDestino ]} /> : false }
                </Col>
                <Col md={3} className='pr-0'>
                    <h5 className='d-block mb-0' style={{fontWeight:900}}><i className="fa-solid fa-boxes-packing"></i> Datos de distribución</h5>
                    <Badge variant="secondary" className='mb-3 mt-0'>OPCIONAL</Badge>
                </Col>
                <Col md={9} className='pl-0'><hr/></Col>      
                <Col md={12} className='mb-3'>
                    <label className='form-control-label d-block' style={{fontWeight:700}}>Courier/Cuenta</label>
                    <select className="form-control" name="courrier" onChange={handleChangeOrden} >
                        <option value="">Seleccione</option>
                        {
                            default_proveedores.map((proveedor, i) => {
                                return <option key={`proveedor-${i}`} value={proveedor.slug}>{proveedor.titulo}</option>
                            })
                        }
                    </select>
                </Col>
                {/* <Col md={6}> <SelectorTipoAsignacion onChange={(e) => handleChangeAsignacion(e)} /></Col>                    */}
            <Col xs={12}><SelectorCuentasFacturacion onChange={(data) => handleChangeOrden({ target: { name: "account_id", value: data?.value } })} /> </Col>
            </Row>

            {
                loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>crearNuevaRuta()} disabled={statusBoton}>CREAR</Button>
            }
            </Card>
        </Col>
    </Row>
    {mostrarModalCotizacion()}
</div>
}

export default CrearOrden