import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
// import 'bootstrap/dist/css/bootstrap.min.css'
import './app.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-loading-skeleton/dist/skeleton.css'
import Home from '../Home';
import Usuarios from '../Usuarios';
import UsuariosCrear from '../UsuariosCrear';
import UsuariosEditar from '../UsuariosEditar';
import Ordenes from '../Ordenes';
import EstadosCarga from '../EstadosCarga';
import Flujos from '../Flujos';
import Rutas from '../Rutas';
import CrearEnvio from '../RutasImportar/individual';
import RutasImportar from '../RutasImportar';
import RutasImportarAvanzado from '../RutasImportar/avanzado';
import Vehiculos from '../Vehiculos';
import VehiculosCrear from '../VehiculosCrear';
import VehiculosEditar from '../VehiculosEditar';
import VehiculosImportar from '../VehiculosImportar';
import Configuracion from '../Configuracion';
import Actividad from '../Actividad';
import Proveedores from '../Proveedores';
import Estadisticas from '../Estadisticas';
import CamposPersonalizados from '../CamposPersonalizados';
import RutasCrear from '../RutasCrear';
import RutasEditar from '../RutasEditar';
import OrdenesCrear from '../OrdenesCrear';
import MiCuenta from '../Cuenta';
import OlvideContrasena from '../OlvideContrasena';
import RutasAsignaciones from '../RutasAsignaciones';
import Geocercas from '../Geocercas';
import Planificador from '../Planificador';
import Etiquetas from '../Etiquetas';
import BodegaUsuarios from '../BodegaUsuarios';
import Tracking from '../Tracking';
import ExcelFormats from '../ExcelFormats';
import Integraciones from '../Integraciones';
import Expediciones from '../Bodega/Expediciones';
import Canales from '../Canales';
import PoliticasPrivacidad from '../Informacion/politicas_privacidad';
import 'react-circular-progressbar/dist/styles.css';
import Widget from '../Widget';
import Login from '../Login';
import { rutas } from "../../lib/routes/routes";
import DetallesPedido from "../../subComponents/ordenes/detalles";
import Bodegas from "../Bodegas";
import ActividadFlota from "../../subComponents/actividad/flota";
import ActividadEstadosCarga from "../../subComponents/actividad/estados_carga";
import RendimientoProveedores from "../../subComponents/actividad/proveedores/rendimiento";
import OperacionMisClientes from "../../subComponents/actividad/misclientes";
import ActividadAuditoria from "../../subComponents/actividad/auditoria";
import MercadoLibreConexion from "../../subComponents/integraciones/plugins/autorization_pages/mercadolibre";
import DetallesApiless from "../../subComponents/integraciones/apiless/detalles";
import ProveedoresTransporte from "../../subComponents/proveedores_transporte";
import ProveedorEnlazadoDetalle from "../../subComponents/proveedores_transporte/enlace/detalles";
import FuncionesCrear from "../../subComponents/integraciones/funciones/crear_funcion";
import Clientes from "../../subComponents/clientes";
import ClienteEnlazadoDetalle from "../../subComponents/clientes/enlace/detalles";
import CrearLoteTarifas from "../../subComponents/pricing/tarifas/lotes/crear";
import LoteDetalles from "../Pricing/lotes";
import Facturacion from "../../subComponents/facturacion";
import { Chart, LineController, LineElement, PointElement, BarElement, LinearScale, Tooltip, Title,CategoryScale, ArcElement, Legend } from 'chart.js';
import Header from "../Header";
import Structure from "../Structure";
import ActividadLanding from "../../subComponents/landing";
import EstadisticaTrackingConductor from "../Estadistica/tracking";
import Zonas from "../Zonas";
import MantenedorGeocerca from "../RadioOperacion/Geogercas/creador";
import MantenedorEsquema from "../Structure/mantenedorEsquema";
import DetallesZona from "../Zonas/detalles";
import Alertas from "../GeoAlertas";
import GeoAlertasMapa from "../GeoAlertas/geo_alertas_mapa";
import DestinatariosListado from "../../subComponents/destinatarios/list";
import DetailEnlaceRuta from "../../subComponents/rutas/rutas_enlaces/detalle_publico";
import InformeRastreoOrden from "../../subComponents/ordenes/ordenes_zonas/informe_rastreo";
import InstruccionConexion from "../../subComponents/mensajeria/appConductor/instruccion_conexion";
import ListadoWhatsapp from "../../subComponents/listas-whatsapp/listadoWhatsapp/list";
import DestinatariosImportar from "../DestinatariosImportar";
import FacturacionCliente from "../../subComponents/facturacion/clientes";
import ProductosListado from "../../subComponents/productos/list";
import ProductosImportar from "../ProductosImportar";
import ModalProductos from "../../subComponents/productos/modal_productos";
import CentinelaListado from "../../subComponents/centinela/listado";
import RecoleccionesListado from "../../subComponents/recolecciones/list";
import ModalRecolecciones from "../../subComponents/recolecciones/modal_recolecciones";
import ResultadoMetodoPago from "../../subComponents/financiero/resultado_metodo_pago";
import PagosListado from "../../subComponents/payments/list";
import Register from "../Register/register";
import InformeCourriers from "../../subComponents/courriers/clientes/dashboard";
import ReglasNegocioFormulario from "../../subComponents/reglas-negocio";
import ResetContrasena from "../OlvideContrasena/resetContrasena";
import OrdenesListadoV3 from "../../subComponents/ordenes/listado_V3";
import OrdenTicketsListado from "../../subComponents/ordenes/ordenes_tickets/list";
import DetallesRecibo from "../../subComponents/ordenes/receipt";
import DetallesDocumento from "../../subComponents/formatos/document";
import LoginExternal from "../Login/external";
import ReglasList from "../../subComponents/reglas-listado/listado";
import Envios from "../Envios";
import LoteDetallesCliente from "../Pricing/lotes/client_version";
import TarifasCotizacion from "../../subComponents/pricing/tarifas_cotizacion";
import RuteoSimple from "../../subComponents/ruteo/ruteo_simple";
import Estatico from "../../subComponents/ordenes/receipts";
import RuteoAvanzado from "../../subComponents/ruteo/ruteo_avanzado";
import OrdenesGestionadaListado from "../../subComponents/ordenes/listado_gestionadas";
import BodegasImportador from "../../subComponents/destinatarios/importador/importador_bodegas";
import ContactosImportador from "../../subComponents/destinatarios/importador/importador_contactos";
import ActividadConductores from "../../subComponents/conductores/actividad";
import OrdenTicketsListadoProveedor from "../../subComponents/ordenes/tickets_proveedor/list";
import DetailFullTicketPublic from "../../subComponents/ordenes/tickets_proveedor/detalle-publico";
import EstadisticaMensualProveedores from "../../subComponents/estadisticas/estadistica_mensual_proveedores";
import DestinatariosBodegasListadoGeneral from "../../subComponents/destinatarios/destinatarios_bodegas_general/list";
import DestinatariosContactosListadoGeneral from "../../subComponents/destinatarios/destinatarios_contactos_general/list";
import CrearNotificacionesMasivas from "../../subComponents/notificaciones/notificacion_masiva";
import LoginExternalIntegration from "../Login/external_integrated";
import Empresas from '../Empresas';
import EmpresasCrear from '../Empresas/crear';
import EmpresasEditar from '../Empresas/editar';
import PlanListado from "../../subComponents/plan/list";
import MetodosPagoListado from "../../subComponents/metodos_pago/list";
import Drivers from "../../subComponents/ordenes/conductores/list";

Chart.register(LineController, LineElement, PointElement, BarElement, Legend, LinearScale, ArcElement, Title, Tooltip, CategoryScale);

const RoutesApp = (props) => {

    const session = useSelector(state => state.miusuario)

    const outside_session = () => {
        return <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/login-external" element={<LoginExternal />} />
            <Route path="/login-external-integration" element={<LoginExternalIntegration />} />
            <Route  path="/registro" element={<Register/>} />
            <Route  path="/olvide-password" element={<OlvideContrasena/>} />
            <Route path="/reset-password" element={<ResetContrasena/>} />
            <Route path="/tracking/:id" element={<Tracking/>} />
            <Route path="/widget/:id" element={<Widget/>} />
            <Route path='*' element={<Navigate to="/login" />} />
            <Route  path="/politicas-privacidad" element={<PoliticasPrivacidad/>} />
            <Route  path={`${rutas.share_route.slug}/:id`} element={<DetailEnlaceRuta />} />
            <Route  path={`/${rutas.instrucciones.slug}`} element={<InstruccionConexion/>} />
            <Route  path={`/${rutas.tickets_public_access.slug}/:id`} element={<DetailFullTicketPublic />} />
        </Routes>
    }

    // USUARIO NO LOGEADO
    if(!session) return outside_session()
    if(!session.data) return outside_session()

    const routerComponent = (
        <main className="col-md-12 ms-sm-auto col-lg-12 leftauto" style={{ paddingTop: 15, height: "100%" }}>
            <Routes>
                    <Route  path="/" element={<Home />} />
                    <Route  path={`/${rutas.custom_fields.slug}`} element={<CamposPersonalizados />} />
                    <Route  path={`/${rutas.excel_formats.slug}`} element={<ExcelFormats />} />
                    <Route  path={`/${rutas.geocercas.slug}`} element={<Geocercas />} />
                    <Route  path={`/${rutas.canales.slug}`} element={<Canales />} />
                    <Route  path="/account" element={<MiCuenta />} />
                    <Route  path={`/${rutas.actividad.slug}`} element={<Actividad />} />
                    <Route  path={`/${rutas.actividad_flota.slug}`} element={<ActividadFlota />} />
                    <Route  path={`/${rutas.actividad_estados.slug}`} element={<ActividadEstadosCarga />} />
                    <Route  path={`/${rutas.actividad_auditoria.slug}`} element={<ActividadAuditoria />} />
                    <Route  path={`/${rutas.rendimiento_proveedores.slug}`} element={<EstadisticaMensualProveedores />} />
                    <Route  path={`/${rutas.mercadolibre_config.slug}`} element={<MercadoLibreConexion />} />
                    <Route  path={`/${rutas.apiless_detail.slug}/:id`} element={<DetallesApiless />} />
                    <Route  path={`/${rutas.empresas.slug}`} element={<Empresas/>} />
                    <Route  path={`/${rutas.empresas.slug}/nuevo`} element={<EmpresasCrear/>} />
                    <Route  path={`/${rutas.empresas.slug}/edit/:id`} element={<EmpresasEditar/>} />
                    <Route  path={`/${rutas.notificaciones_crear.slug}`} element={<CrearNotificacionesMasivas />} />

                    <Route  path={`/my-drivers`} element={<Drivers />} />

                    <Route  path={`/${rutas.facturacion.slug}`} element={<Facturacion />} />
                    <Route  path={`/${rutas.facturacion_cliente.slug}`} element={<FacturacionCliente />} />

                    <Route  path={`/${rutas.clientes.slug}`} element={<Clientes />} />
                    <Route  path={`/${rutas.operacion_en_curso.slug}`} element={<OperacionMisClientes />} />
                    <Route  path={`/${rutas.planes.slug}`} element={<PlanListado />} />
                    <Route  path={`/${rutas.metodos_pago.slug}`} element={<MetodosPagoListado />} />
                    <Route  path="/estadisticas" element={<Estadisticas />} />
                    <Route  path="/configuracion" element={<Configuracion />} />
                    <Route  path="/bodegas" element={<Bodegas/>} />
                    <Route  path="/proveedores" element={<Proveedores/>} />
                    <Route  path="/vehiculos" element={<Vehiculos/>} />
                    <Route  path="/vehiculos/nuevo" element={<VehiculosCrear/>} />
                    <Route  path="/vehiculos/edit/:id" element={<VehiculosEditar/>} />
                    <Route  path="/vehiculos/importar" element={<VehiculosImportar/>} />
                    <Route  path="/usuarios" element={<Usuarios/>} />
                    <Route  path="/estados-carga" element={<EstadosCarga/>} />
                    <Route  path="/flujos-carga" element={<Flujos/>} />
                    <Route  path="/etiquetas" element={<Etiquetas/>} />
                    <Route  path={`/${rutas.integraciones.slug}`} element={<Integraciones/>} />
                    <Route  path={`/${rutas.integraciones_funciones_crear.slug}`} element={<FuncionesCrear />} />
                    <Route  path={`/${rutas.bodega_usuarios.slug}`} element={<BodegaUsuarios/>} />
                    <Route  path="/ordenes/:id" element={<DetallesPedido />} />
                    <Route  path="/receipt/:id" element={<DetallesRecibo />} />
                    <Route  path="/print-receipts" element={<Estatico />} />
                    <Route  path="/document/:id" element={<DetallesDocumento />} />
                    <Route  path="/receipt/:id" element={<DetallesRecibo />} />                    
                    <Route  path="/ordenes" element={<Ordenes/>} />
                    <Route  path="/ordenes/nuevo" element={<OrdenesCrear/>} />
                    <Route  path="/planificador" element={<Planificador/>} />
                    <Route  path={`/${rutas.bodega_expediciones.slug}`} element={<Expediciones/>} />
                    <Route  path={`/${rutas.logistic_providers.slug}`} element={<ProveedoresTransporte />} />
                    <Route  path={`/${rutas.logistic_providers_pricing.slug}`} element={<TarifasCotizacion />} />
                    <Route  path={`/${rutas.logistic_providers_link.slug}/:id`} element={<ProveedorEnlazadoDetalle />} />
                    <Route  path={`/${rutas.clientes.slug}/:id`} element={<ClienteEnlazadoDetalle />} />
                    <Route  path="/rutas" element={<Rutas/>} />
                    <Route  path={`/${rutas.landing.actividad.slug}/:slug`} element={<ActividadLanding/>} />
                    <Route  path="/rutas-asignaciones" element={<RutasAsignaciones/>} />
                    <Route  path="/rutas/importar" element={<RutasImportar/>} />
                    <Route  path={`${rutas.crear_orden.slug}`} element={<CrearEnvio />} />
                    <Route  path="/rutas/importar-avanzado" element={<RutasImportarAvanzado/>} />
                    <Route  path={`/${rutas.zones.slug}`} element={<Zonas />} />
                    <Route  path={`/${rutas.zones.slug}/new`} element={<MantenedorGeocerca />} />
                    <Route  path={`${rutas.zones.slug}/:id`} element={<DetallesZona />} />
                    <Route  path={rutas.alerts.slug} element={<Alertas />} />
                    <Route  path={rutas.map_alerts.slug} element={<GeoAlertasMapa />} />
                    <Route  path={`/${rutas.pricing_lote_nuevo.slug}`} element={<CrearLoteTarifas />} />
                    <Route  path={`/${rutas.pricing_lotes.slug}/:id`} element={<LoteDetalles />} />
                    <Route  path={`/${rutas.pricing_lotes.slug}/client/:id`} element={<LoteDetallesCliente />} />
                    <Route  path={`/${rutas.estadisticas_tracking_conductor.slug}`} element={<EstadisticaTrackingConductor />} />
                    <Route  path={`/${rutas.destinatarios.slug}`} element={<DestinatariosListado />} />
                    <Route  path={`/${rutas.destinatarios_bodegas_general.slug}`} element={<DestinatariosBodegasListadoGeneral />} />
                    <Route  path={`/${rutas.destinatarios_contactos_general.slug}`} element={<DestinatariosContactosListadoGeneral />} />
                    <Route  path={`/${rutas.destinatarios_importar_excel.slug}`} element={<DestinatariosImportar/>} />
                    <Route  path={`/${rutas.destinatarios_bodegas_importar_excel.slug}`} element={<BodegasImportador />} />
                    <Route  path={`/${rutas.destinatarios_contactos_importar_excel.slug}`} element={<ContactosImportador />} />
                    <Route  path={`/${rutas.ruteo_simple.slug}`} element={<RuteoSimple />} />
                    <Route  path={`/${rutas.ruteo_avanzado.slug}`} element={<RuteoAvanzado />} />
                    <Route  path={`/${rutas.auditoria_conductores.slug}`} element={<ActividadConductores />} />
                    <Route  path="/rutas/nuevo" element={<RutasCrear/>} />
                    <Route  path="/rutas/:id" element={<RutasEditar/>} />
                    <Route  path="/usuarios/nuevo" element={<UsuariosCrear/>} />
                    <Route  path="/usuarios/edit/:id" element={<UsuariosEditar/>} />
                    <Route  path="/politicas-privacidad" element={<PoliticasPrivacidad/>} />
                    <Route  path="/tracking/:id" element={<Tracking/>} />
                    <Route  path="/widget/:id" element={<Widget/>} />
                    <Route  path="/olvide-password" element={<OlvideContrasena/>} />
                    <Route  path="/instrucciones" element={<InstruccionConexion/>} />
                    <Route  path={`${rutas.share_route.slug}/:id`} element={<DetailEnlaceRuta />} />
                    <Route  path={`${rutas.report_tracking.slug}/:id`} element={<InformeRastreoOrden />} />
                    <Route  path={`/${rutas.instrucciones.slug}`} element={<InstruccionConexion/>} />
                    <Route  path={`/${rutas.centinela.slug}`} element={<CentinelaListado />} />
                    <Route  path="/mantenedor-esquema" element={<MantenedorEsquema/>} />
                    <Route  path={`/${rutas.productos.slug}`} element={<ProductosListado />} />
                    <Route  path={`/${rutas.tickets_business.slug}`} element={<OrdenTicketsListadoProveedor />} />
                    <Route  path={`/${rutas.tickets.slug}`} element={<OrdenTicketsListado />} />
                    <Route  path={`/${rutas.tickets_pending.slug}`} element={<OrdenTicketsListado condicion_defajult={{ open: { $nin: [false] } }} />} />
                    <Route  path={`/${rutas.productos_importar_excel.slug}`} element={<ProductosImportar/>} />
                    <Route  path="/modal/productos" element={<ModalProductos/>} />
                    <Route  path={`/${rutas.recolecciones.slug}`} element={<RecoleccionesListado/>} />
                    <Route  path="/modal/recolecciones" element={<ModalRecolecciones/>} />
                    <Route  path={`/${rutas.payments_result.slug}`} element={<ResultadoMetodoPago />} />
                    <Route  path={`/${rutas.centinela_nuevo.slug}`} element={<ReglasNegocioFormulario />} />
                    <Route  path={`/${rutas.centinela_rules.slug}`} element={<ReglasList />} />
                    <Route  path={`/${rutas.payments.slug}`} element={<PagosListado />} />
                    <Route  path={`/${rutas.dashboard_courriers.slug}`} element={<InformeCourriers />} />
                    <Route  path={`/${rutas.ordenes_gestionadas.slug}`} element={<OrdenesGestionadaListado showFilter={false} showExporter={false} gestionadas={true} />} />

                    { /*  COURRIERS ROUTES */ }
                    <Route  path={`/${rutas.envios.slug}`} element={<Envios />} />
                    <Route  path={`/${rutas.todos_envios.slug}`} element={<OrdenesListadoV3  />} />
                    <Route path='*' element={<Navigate to="/" />} />

                </Routes>
            </main>
    )

    return <Structure router={routerComponent} />
    
}

export default RoutesApp